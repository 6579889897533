/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/06/06 10:37
 * @file src/actions/device-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';
import Config from "../config";

export function getNumberBadge(cb) {
    return (dispatch) => {
        const appID = Config.env.productID || "WCRM";
        Api.get('/notification/badge?AppID=' + appID, {})
            .then(result => {
                if(result && result.code && result.message !=='') {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result);
                    dispatch(getNumberBadgeComplete(result && result.data && result.data.badge ? result.data.badge : 0 ));
                }
            });
    };
}

export function getListNotification(param, cb) {
    return (dispatch) => {
        Api.put('/notification/search', param)
            .then(result => {
                // console.log(result);
                if (result && result.data) {
                    if(cb) cb(null, result.data);
                    dispatch(getListNotificationComplete(result.data.rows || []));
                } else {
                    if(cb) cb(result, null);
                }
            });
    };
}

export function getListNotificationUnread(param, cb) {
    return (dispatch) => {
        Api.put('/notification/unread', param)
            .then(result => {
                // console.log(result);
                if (result && result.data) {
                    if(cb) cb(null, result.data);
                    dispatch(getListNotificationComplete(result.data.rows || []));
                } else {
                    if(cb) cb(result, null);
                }
            });
    };
}

export function updateNotification(param, cb) {
    return (dispatch) => {
        Api.put('/Notification/update-status', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result);
                    dispatch(updateNotificationComplete(result.data));
                }
            });
    };
}

export function listUser(cb) {
    return () => {
        // console.log("============== listCompany -> param: ", param);

        Api.get('/cache/get-list-of-user-cache')
            .then(listUser => {
                // console.log("================================ userLists:", listUser);
                if(listUser && listUser.data){
                    Config.setLocalStorage('LISTUSER', Config.encryptData(JSON.stringify(listUser.data)));
                    cb && cb(null,listUser.data);
                }
            })
            .catch(userError => {
                if (!userError || !userError.code) return false;
                Config.removeLocalStorage('LISTUSER');
                cb && cb(userError, null);
            });
    };
}

function getNumberBadgeComplete(data) {
    return {
        type: types.GET_NUMBER_BADGE_COMPLETE,
        numberBadge: data
    };
}

function getListNotificationComplete(data) {
    return {
        type: types.LIST_NOTIFICATIONS_COMPLETE,
        listNotification: data
    };
}

function updateNotificationComplete(data) {
    return {
        type: types.UPDATE_NOTIFICATIONS_COMPLETE,
        listNotification: data
    };
}
