/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2019/10/02 15:37
 * @update 2019/10/02 15:37
 */
import Api from "../services/api";
import * as types from "./types";

export function cbCaseTypesW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1061/get-case-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCaseTypeComplete(result.data));
                }
            });
    };
}

function loadCaseTypeComplete(data) {
    return {
        type: types.W18_W18F1061_CB_CASETYPE_COMPLETE,
        cbCaseTypes: data
    }
}

export function cbMediaW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/w20f1001/cbo-media', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadMediaComplete(result.data));
                }
            });
    };
}

function loadMediaComplete(data) {
    return {
        type: types.W18_W18F1061_CB_MEDIA_COMPLETE,
        cbMedia: data
    }
}

export function cbStatusW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/dropDownTypeTime/d-w-list-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadStatusComplete(result.data));
                }
            });
    };
}

function loadStatusComplete(data) {
    return {
        type: types.W18_W18F1061_CB_STATUS_COMPLETE,
        cbStatus: data
    }
}

export function cbCreatePersonW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1061/get-create-person', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCreatePersonComplete(result.data));
                }
            });
    };
}

function loadCreatePersonComplete(data) {
    return {
        type: types.W18_W18F1061_CB_CREATEPERSON_COMPLETE,
        createPerson: data
    }
}

export function cbCustomerInfoW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1061/customer-info', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadEditFormW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1061/get-form-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadEditFormW18F1061Complete(result.data));
                }
            });
    };
}

function loadEditFormW18F1061Complete(data) {
    return {
        type: types.W18_W18F1061_EDIT_FORM_COMPLETE,
        loadEditW18F1061: data
    }
}

export function addW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1061/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(addW18F1061Complete(result.data));
                }
            });
    };
}

function addW18F1061Complete(data) {
    return {
        type: types.W18_W18F1061_ADD_COMPLETE,
        addW18F1061: data
    };
}

export function editW18F1061(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1061/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(editW18F1061Complete(result.data));
                }
            });
    };
}

function editW18F1061Complete(data) {
    return {
        type: types.W18_W18F1061_EDIT_COMPLETE,
        addW18F1061: data
    };
}

export function addHistoryW18F1061(param, cb) {
    return async () => {
        await Api.put('/history/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                    return true;
                } else {
                    if (cb) cb(null, result.data);
                    return true;

                }
            });
    };
}

export function getAttach(param, cb) {
    return () => {
        Api.put('/w18f1061/data-by-key-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

export function getListComment(param,cb){
    return ()=>{
        Api.put('/comment/get-list', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function addComment(param,cb){
    return ()=>{
        Api.put('/comment/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function editComment(param,cb){
    return ()=>{
        Api.put('/comment/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function removeComment(param,cb){
    return ()=>{
        Api.put('/comment/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function checkTask(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1061/check-task', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
