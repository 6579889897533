/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/actions/dropdown-actions.js
 */

import Api from '../../../services/api.js';
export default async (param)=> {
    return new Promise((resolve, reject) => {
        Api.put('/w18f1030/W18F1022cbUserReceive', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    return resolve(0);
                } else {
                    return resolve(result.data);
                }
            });
    });
};
