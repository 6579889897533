/**
 * @copyright 2019 @ DigiNet
 * @author Tram
 * @create 2019/02/21 09:37
 * @update 2019/02/21 09:37
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function detailW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1031/load-store-W17P1031', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(detailW18F1031Complete(result));
                }
            });
    };
}

function detailW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_DETAIL_COMPLETE,
        detailW18F1031: data
    };
}

export function storeW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1031/load-store-W17P1033', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(storeW18F1031Complete(result));
                }
            });
    };
}

function storeW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_STORE_COMPLETE,
        storeW18F1031: data
    };
}

export function addW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1030/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(addW18F1031Complete(result));
                }
            });
    };
}

function addW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_ADD_COMPLETE,
        addW18F1031: data
    };
}

export function editW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1030/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(editW18F1031Complete(result));
                }
            });
    };
}

function editW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_EDIT_COMPLETE,
        editW18F1031: data
    };
}


export function actionW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1031/user-info', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(actionW18F1031Complete(result));
                }
            });
    };
}

function actionW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_ACTION_COMPLETE,
        actionW18F1031: data
    };
}

export function comboGroupSalesW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1031/load-store-W17P9000', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(comboGroupSalesComplete(result));
                }
            });
    };
}

function comboGroupSalesComplete(data) {
    return {
        type: types.W18_W18F1031_GroupSales_COMPLETE,
        comboGroupSalesW18F1031: data
    };
}

export function loadDataAttchW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1031/data-by-sales-person-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadDataAttchW18F1031Complete(result.data));
                }
            });
    };
}

function loadDataAttchW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_LOADATTCH_COMPLETE,
        loadDataAttchW18F1031: data
    };
}

export function loadGroupSalesW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1031/load-store-W17P1032', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadGroupSalesW18F1031Complete(result));
                }
            });
    };
}

function loadGroupSalesW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_LOADGROUPSALES_COMPLETE,
        loadGroupSalesW18F1031: data
    };
}

export function getStatusW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1031/get-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(getStatusW18F1031Complete(result.data));
                }
            });
    };
}

function getStatusW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_STATUS_COMPLETE,
        getStatusW18F1031: data
    };
}
