/**
 * @copyright 2019 @ DigiNet
 * @author Tram
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadW18F1040(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1040/search-i-D17T0060', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW18F1040Complete(result));
                }
            });
    };
}

function loadW18F1040Complete(data) {
    return {
        type: types.W18_W18F1040_LOAD_COMPLETE,
        loadW18F1040: data
    };
}

export function addW18F1040(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1040/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(adđW18F1040Complete(result));
                }
            });
    };
}

function adđW18F1040Complete(data) {
    return {
        type: types.W18_W18F1040_ADD_COMPLETE,
        addW18F1040: data
    };
}

export function editW18F1040(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1040/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(editW18F1040Complete(result));
                }
            });
    };
}

function editW18F1040Complete(data) {
    return {
        type: types.W18_W18F1040_EDIT_COMPLETE,
        editW18F1040: data
    };
}

export function deleteW18F1040(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1040/del-by-norm-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW18F1040Complete(result));
                }
            });
    };
}

function deleteW18F1040Complete(data) {
    return {
        type: types.W18_W18F1040_DELETE_COMPLETE,
        deleteW18F1040: data
    };
}

export function loadW18F1041(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1041/data-by-norm-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW18F1041Complete(result));
                }
            });
    };
}

function loadW18F1041Complete(data) {
    return {
        type: types.W18_W18F1041_LOAD_COMPLETE,
        loadW18F1041: data
    };
}

export function addW18F1041(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1041/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(addW18F1041Complete(result));
                }
            });
    };
}

function addW18F1041Complete(data) {
    return {
        type: types.W18_W18F1041_ADD_COMPLETE,
        addW18F1041: data
    };
}

export function checkDeleteW18F1040(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1040/check-by-norm-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data[0]);
                    dispatch(checkDeleteW18F1040Complete(result.data));
                }
            });
    };
}

function checkDeleteW18F1040Complete(data) {
    return {
        type: types.W18_W18F1040_CHECKDELETE_COMPLETE,
        checkDeleteW18F1040: data[0]
    };
}

export function editW18F1041(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1041/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(editW18F1041Complete(result));
                }
            });
    };
}

function editW18F1041Complete(data) {
    return {
        type: types.W18_W18F1041_EDIT_COMPLETE,
        editW18F1041: data
    };
}

export function deleteW18F1041(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1041/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW18F1041Complete(result));
                }
            });
    };
}

function deleteW18F1041Complete(data) {
    return {
        type: types.W18_W18F1041_DELETE_COMPLETE,
        deleteW18F1041: data
    };
}

export function checkDeleteW18F1041(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1040/check-by-value-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data[0]);
                    dispatch(checkDeleteW18F1041Complete(result.data));
                }
            });
    };
}

function checkDeleteW18F1041Complete(data) {
    return {
        type: types.W18_W18F1041_CHECKDELETE_COMPLETE,
        checkDeleteW18F1041: data[0]
    };
}

