/**
 * @copyright 2021 @ DigiNet
 * @author thanhhai
 * @create 2021/03/13
 * @update 2021/03/13
 */

import * as types from '../../types.js';
import Api from '../../../services/api.js';



export function loadGrid(param, cb) {
    // Delete other table too
    return (_dispatch) => {
        Api.put('/w17f2050/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    _dispatch({
                        type: types.W17F2050_LOAD_GRID_COMPLETE,
                        data: result.data
                    });
                }
            });
    };
}
export function checkEdit(param, cb) {
    // Delete other table too
    return (_dispatch) => {
        Api.put('/w17f2050/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    
                }
            });
    };
}
export function deleteGrid(param, cb) {
    // Delete other table too
    return (_dispatch) => {
        Api.delete('/w17f2050/delete', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                   
                }
            });
    };
}
