/**
 * @copyright 2019 @ DigiNet
 * @author Tram
 * @create 2019/03/15 11:30
 * @update 2019/03/15 11:30
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function changePassW17F0020(param, cb) {
    // console.log("changePassW17F0020", param);
    return (dispatch) => {
        Api.put('/user/change-password', param)
            .then(result => {
                // console.log("result",result)
                // console.log("param",param)
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(changePassW17F0020Complete(result.data));
                }
            });
    };
}

function changePassW17F0020Complete(data) {
    return {
        type: types.W17_W17F0020_LOAD_COMPLETE,
        changePassW17F0020: data
    };
}
