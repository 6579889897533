/**
 * @copyright 2019 @ DigiNet
 * @author Tram
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadW18F1021(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1021/load-store-W17P1021', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW18F1021Complete(result));
                }
            });
    };
}

function loadW18F1021Complete(data) {
    return {
        type: types.W18_W18F1021_LOAD_COMPLETE,
        loadW18F1021: data
    };
}

export function comboGroupSalesW18F1021(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1021/load-store-W17P9000', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(comboGroupSalesW18F1021Complete(result.data));
                }
            });
    };
}

function comboGroupSalesW18F1021Complete(data) {
    return {
        type: types.W18_W18F1021_GroupSales_COMPLETE,
        comboGroupSalesW18F1021: data
    };
}

export function comboSalesPersonW18F1021(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1021/data-by-group-sales-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(comboSalesPersonW18F1021Complete(result.data));
                }
            });
    };
}

function comboSalesPersonW18F1021Complete(data) {
    return {
        type: types.W18_W18F1021_SalesPerson_COMPLETE,
        comboSalesPersonW18F1021: data
    };
}

export function comboTypeTimeW18F1021(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1021/data-w-type-time', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(comboTypeTimeW18F1021Complete(result.data));
                }
            });
    };
}

function comboTypeTimeW18F1021Complete(data) {
    return {
        type: types.W18_W18F1021_TypeTime_COMPLETE,
        comboTypeTimeW18F1021: data
    };
}

