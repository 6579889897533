/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 08/03/2021
 * @update 08/03/2021
 */

import Api from "../../../services/api";

//Load data grid
export function loadGridW17F2042(param, cb) {
    return () => {
        Api.put('/w17f2042/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//Load combo inventory
export function loadComboInventoryID(param, cb) {
    return () => {
        Api.put('/w17f2042/load-drd-inventory', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//Save data
export function saveData(param, cb) {
    return () => {
        Api.post('/w17f2042/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}