/**
 * @copyright 2020 @ DigiNet
 * @author CANHTOAN
 * @create 2020/07/29 13:47
 * @update 2020/07/29 13:47
 */

import Api from '../services/api.js';

export function addW18F1027(param, cb) {
    return (_dispatch) => {
        Api.put('/w18f1027/save-add-new', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function editW18F1027(param, cb) {
    return (_dispatch) => {
        Api.put('/w18f1027/save-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}
