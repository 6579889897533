/**
 * @copyright 2023 @ DigiNet
 * @author ANHTAI
 * @create 2023/04/15 15:54
 * @update 2023/04/15 15:54
 */

import Api from "../../../services/api";
import * as types from "../../types";

export function loadSettingForm(cb) {
    return (dispatch) => {
        Api.get('/w17f1005/load-form')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadSettingFormComplete(result.data));
                }
            });
    };
}

function loadSettingFormComplete(data) {
    return {
        type: types.W17_W17F1005_GET_DATA_SETTING_FORM,
        dataSettingForm: data
    };
}
export function loadMaster(params, cb) {
    return () => {
        Api.put('/w17f1005/load-data-master', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadMasterCaption(params, cb) {
    return () => {
        Api.put('/w17f1005/load-master', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadDetail(params, cb) {
    return () => {
        Api.put('/w17f1005/load-detail', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadListStatus(params, cb) {
    return () => {
        Api.put('/w17f1005/load-list-status', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadOriginalDecimal(params, cb) {
    return (dispatch) => {
        Api.put('/w17f1005/get-original-decimal', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadOriginalDecimalComplete(result.data));
                }
            });
    };
}

function loadOriginalDecimalComplete(data) {
    return {
        type: types.W17_W17F1005_ORIGINAL_DECIMAL_COMPLETE,
        dataOriginalDecimal: data
    };
}