/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/actions/dropdown-actions.js
 */

import Api from '../../../services/api.js';
export default async (param)=> {
    // console.log('paramparamparamparam');
    // console.log(param);
    return new Promise((resolve, reject) => {
        Api.put('/W19F1001/cboInventory', param)
            .then(result => {
                // console.log('resultresultresultresultresult');
                // console.log(result);
                if (result && result.code && result.message) {
                    return resolve(0);
                } else {
                    return resolve(result.data);
                }
            });
    });
};
