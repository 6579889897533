/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */

import Api from '../../../services/api.js';

export function getCustomerLevel(cb) {
    Api.get('/w20f1002/get-customer-level')
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);

            }
        });
}

export function getCompanyStatus(cb) {
    Api.get('/w20f1002/get-company-status')
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}

export function getGrid(param, cb) {
    Api.put('/w20f1002/grid-customer',param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}

export function saveData(param,cb){
    Api.put('/w20f1002/add',param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}