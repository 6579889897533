/**
 * @copyright 2019 @ DigiNet
 * @author Tram
 * @create 2019/02/27 17:00
 * @update 2019/02/27 17:00
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadComboW18F1026(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1026/get-new-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadComboW18F1026Complete(result.data));
                }
            });
    };
}

function loadComboW18F1026Complete(data) {
    return {
        type: types.W18_W18F1026_LOAD_COMBO_COMPLETE,
        loadComboW18F1026: data
    };
}

export function saveW18F1026(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1026/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(saveW18F1026Complete(result));
                }
            });
    };
}

function saveW18F1026Complete(data) {
    return {
        type: types.W18_W18F1026_SAVE_COMPLETE,
        saveW18F1026: data
    };
}

