/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 15:37
 * @update 2019/03/28 15:37
 */
import Api from "../../../services/api";
import * as types from "../../types";

export function getDataDebtMethod(cb) {
    return (dispatch) => {
        Api.put('/w18f4050/get-cbo-method')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dispatchComplete(result.data, 'getDebtMethod'));
                }
            });
    }
}

export function getDynamicCaption(param, cb) {
    Api.put('/w18f4050/load-caption', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}

export function getTreeData(param, cb) {
    Api.put('/w18f4050/load-stored-W17P4050', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}

export function getDecimal(param, cb) {
    return (dispatch) => {
        Api.put('/w18f4040/get-quantity-decimals', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dispatchComplete(result.data, 'getDecimal'));
                }
            });
    };
}

function dispatchComplete(data, mode) {
    if (mode === 'getDecimal') {
        return {
            type: types.W18_W18F4040_GETDECIMAL_COMPLETE,
            dataDecimal: data
        }
    } else if (mode === 'getDebtMethod') {
        return {
            type: types.W18_W18F4050_DATADEBTMETHOD_COMPLETE,
            dataDebtMethod: data
        }
    }
}