/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */

import Api from '../../../services/api.js';


export function getCboStatus(param, cb) {
    return () => {
        Api.put('/w20f1001/cbo-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);

                }
            });
    }
}

export function getCboSource(param, cb) {
    return () => {
        Api.put('/w20f1001/load-cbo-cus-or-cont', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);

                }
            });
    }
}

export function getCboMedia(param, cb) {
    return () => {
        Api.put('/w20f1001/cbo-media', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}
export function getAttach(param, cb) {
    return () => {
        Api.put('/w20f1001/data-by-key-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

export function getDataMaster(param, cb) {
    return () => {
        Api.put('/w20f1001/grid-edit-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

export function saveData(param,mode, cb) {
    return () => {
        Api.put(`/w20f1001/${mode}`, param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }

}