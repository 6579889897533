/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import Api from '../services/api.js';
import * as types from '../../src/actions/types'


export function deleteTask(param, cb) {
    return () => {
        Api.put('/w18f1014/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function loadCaseTabW18F1012(param, cb) {
    return (dispatch) => {
        Api.put('/W18F1012/get-list', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCaseTabW18F1012Complete(result.data));
                }
            });
    };
}

function loadCaseTabW18F1012Complete(data) {
    return {
        type: types.W18_W18F1012_LOAD_CASETAB_COMPLETE,
        dataGridCase: data
    }
}

export function loadGridDelivery(param, cb) {
    return (dispatch) => {
        Api.put('/W18F1012/load-grid', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                dispatch(loadGridDeliveryComplete(result.data));
            }
        });
    };
}

function loadGridDeliveryComplete(data) {
    return {
        type: types.W18_W18F1012_LOAD_GRID_DELIVERY_COMPLETE,
        dataGridDelivery: data
    }
}

export function deleteDelivery(param, cb) {
    return (_dispatch) => {
        Api.put('/W18F1012/delete-delivery', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
