/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 05/03/2021
 * @update 05/03/2021
 */
import Api from "../../../services/api";

// Combo Status W17F2020
export function getCboStatusW17F2020(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2020/load-cbo-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//Save Data W17F2020
export function saveW17F2020(param, cb) {
    return async () => {
        Api.put('/w17f2020/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}