/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function listNotify(param, cb) {
    return (dispatch) => {
        // console.log("============== listCompany -> param: ", param);

        Api.get('/w17/w17f1000', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(listNotifyComplete(result.data));
                }
            });
    };
}

function listNotifyComplete(data) {
    return {
        type: types.W17_LIST_NOTIFY_COMPLETE,
        listNotify: data
    };
}

const getNestedChildren = function(arr, parent) {
    // console.log('== getNestedChildren => arr: ', arr);
    let out = [];
    for(let i in arr) {
        if(arr[i].ParentMenuID === parent) {
            let children = getNestedChildren(arr, arr[i].MenuID);

            if(children.length) {
                arr[i].children = children;
            }
            out.push(arr[i]);
        }
    }
    return out;
};

export function listMenu(param, cb) {
    return (dispatch) => {
        // console.log("============== listCompany -> param: ", param);

        Api.put('/header/load-store-W17P0001', param)
            .then(result => {
                if(result && result.code && result.code !== 200) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);

                    const data = getNestedChildren(result.data, "");
                    dispatch(listMenuComplete(data));
                }
            });
    };
}

function listMenuComplete(data) {
    return {
        type: types.W17_LIST_MENU_COMPLETE,
        listMenu: data
    };
}

export function loadW17F1555(param, cb) {
    return (dispatch) => {
        Api.put('/W17F1555/data-grid-by-list-type-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW17F1555Complete(result));
                }
            });
    };
}

function loadW17F1555Complete(data) {
    return {
        type: types.W17_W17F1555_GRID_COMPLETE,
        loadW17F1555: data
    };
}

export function loadW17F1555_Combo(param, cb) {
    return (dispatch) => {
        Api.put('/W17F1555/combo-list-type-by-language-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW17F1555_Combo_Complete(result));
                }
            });
    };
}

function loadW17F1555_Combo_Complete(data) {
    return {
        type: types.W17_W17F1555_COMBO_COMPLETE,
        loadW17F1555_Combo: data
    };
}

export function actionW17F1556(param, cb) {
    return (dispatch) => {
        Api.put('/w17F1556/updateData', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(actionW17F1556Complete(result));
                }
            });
    };
}
function actionW17F1556Complete(data) {
    return {
        type: types.W17_W17F1556_ACTION_COMPLETE,
        actionW17F1556: data
    };
}

export function deleteW17F1555(param, cb) {
    return (dispatch) => {
        Api.put('/W17F1555/delete-item-by-list-type-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW17F1555Complete(result));
                }
            });
    };
}
function deleteW17F1555Complete(data) {
    return {
        type: types.W17_W17F1556_ACTION_COMPLETE,
        deleteW17F1555: data
    };
}

export function checkStore(param, cb) {
    return (dispatch) => {
        // console.log('========checkStore========',param)
        Api.put('/mssql/query', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(checkStoreComplete(result));
                }
            });
    };
}
function checkStoreComplete(data) {
    return {
        type: types.CHECKING_STORE_COMPLETE,
        checkStore: data
    };
}

export function loadW17F0001(param, cb) {
    return (dispatch) => {
        Api.put('/w17f0001/search', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW17F0001Complete(result));
                }
            });
    };
}

export function loadW17F0001Complete(data) {
    return {
        type: types.W17_W17F0001_GRID_COMPLETE,
        loadW17F0001: data
    };
}

export function deleteW17F0001(param, cb) {
    return (dispatch) => {
        Api.put('/w17f0001/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW17F0001Complete(result));
                }
            });
    };
}

function deleteW17F0001Complete(data) {
    return {
        type: types.W17_W17F0001_DELETE_COMPLETE,
        delW17F0001: data
    };
}

export function editW17F0001(param, cb) {
    return (dispatch) => {
        Api.put('/w17f0001/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(editW17F0001Complete(result));
                }
            });
    };
}

function editW17F0001Complete(data) {
    return {
        type: types.W17_W17F0001_EDIT_COMPLETE,
        editW17F0001: data
    };
}

export function addW17F0001(param, cb) {
    return (dispatch) => {
        Api.put('/w17f0001/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(addW17F0001Complete(result));
                }
            });
    };
}

function addW17F0001Complete(data) {
    return {
        type: types.W17_W17F0001_ADD_COMPLETE,
        addW17F0001: data
    };
}

export function getW17F0001(param, cb) {
    return (dispatch) => {
        Api.put('/w17f0001/get', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(getW17F0001Complete(result));
                }
            });
    };
}

function getW17F0001Complete(data) {
    return {
        type: types.W17_W17F0001_GET_COMPLETE,
        getW17F0001: data
    };
}

export function checkDeleteW17F1555(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1555/m2-exe-W17P5555', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(checkDeleteW17F1555Complete(result.data));
                }
            });
    };
}

function checkDeleteW17F1555Complete(data) {
    return {
        type: types.W17_W17F1555_CHECKDELETE_COMPLETE,
        checkDeleteW17F1555: data
    };
}

export function checkEditW17F1555(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1555/m1-exe-W17P5555', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(checkEditW17F1555Complete(result.data));
                }
            });
    };
}

function checkEditW17F1555Complete(data) {
    return {
        type: types.W17_W17F1555_CHECKEDIT_COMPLETE,
        checkEditW17F1555: data
    };
}

export function checkAddW17F1556(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1556/m0-exe-W17P5555', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(checkAddW17F1556Complete(result.data));
                }
            });
    };
}

function checkAddW17F1556Complete(data) {
    return {
        type: types.W17_W17F1556_CHECKADD_COMPLETE,
        checkAddW17F1556: data
    };
}

