/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function apiQuery(param, mode, cb) {
    //API  dung  de query  sql
    return (dispatch) => {
        Api.put('/mssql/query', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(apiQueryComplete(result.data, mode));
                }
            });
    };
}

export function loadCBTaskType(param, cb) {
    //API  dung  de query  sql
    return (dispatch) => {
        Api.put('/D17T0070/get', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(apiQueryComplete(result.data, 'TaskType'));
                }
            });
    };
}

export function cboSalePerson(param, cb) {
    return (dispatch) => {
        Api.put('/dropDownSalePerson/d-w-gr-sales-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboSalePersonComplete(result.data));
                }
            });
    };
}

function cboSalePersonComplete(data) {
    return {
        type: types.TEMPLATE_DROPDOWN_SALEPERSON_COMPLETE,
        cboSalePerson: data
    };
}

export function cboReportTemplate(param, cb, mode) {
    // mode =1 dont cache reducer
    return (dispatch) => {
        Api.put('/dropDownReportTemplate/d-by-report-type-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    if (!mode) dispatch(cboReportTemplateComplete(result.data));
                }
            });
    };
}

function cboReportTemplateComplete(data) {
    return {
        type: types.TEMPLATE_DROPDOWN_REPORTTEMPLATE_COMPLETE,
        cboReportTemplate: data
    };
}

export function cboTypeTime(param, cb) {
    return (dispatch) => {
        Api.put('/dropDownTypeTime/d-w-list-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboTypeTimeComplete(result.data));
                }
            });
    };
}

function cboTypeTimeComplete(data) {
    return {
        type: types.TEMPLATE_DROPDOWN_TYPETIME_COMPLETE,
        cboTypeTime: data
    };
}

function apiQueryComplete(data, mode) {
    switch (mode) {
        // case 'ReportTemplate':
        //     return {
        //         type: types.TEMPLATE_DROPDOWN_REPORTTEMPLATE_COMPLETE,
        //         cboReportTemplate: data
        //     };
        // case 'SalePerson':
        //     return {
        //         type: types.TEMPLATE_DROPDOWN_SALEPERSON_COMPLETE,
        //         cboSalePerson: data
        //     };
        // case 'TypeTime':
        //     return {
        //         type: types.TEMPLATE_DROPDOWN_TYPETIME_COMPLETE,
        //         cboTypeTime: data
        //     };
        case 'TaskType':
            return {
                type: types.TEMPLATE_DROPDOWN_TASKTYPE_COMPLETE,
                cboTaskType: data
            };
        default:
            return null;

    }

}

export function cboReportType(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1060/get-data-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboReportTypeComplete(result.data));
                }
            });
    };
}

function cboReportTypeComplete(data) {
    return {
        type: types.TEMPLATE_DROPDOWN_REPORTTYPE_COMPLETE,
        cboReportType: data
    };
}

export function getcboCustomer(param) {
    return new Promise((resolve) => {
        Api.put('/w18f4040/get-cbo-customer', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    resolve(null);
                } else {
                    resolve(result.data)
                }
            });
    })
}

export function getCboArea(cb) {
    return (dispatch) => {
        Api.get('/w18f4070/load-cbo-sales-office')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dispatchComplete('getCboArea', result.data))
                }
            });
    }
}

function dispatchComplete(mode, data) {
    if (mode === 'getCboArea')
        return {
            type: types.TEMPLATE_DROPDOWN_AREATEMPLATE_COMPLETE,
            cboArea: data
        }
}

export function getCboObject(param) {
    return new Promise((resolve) => {
        Api.put('/W18/load-standard-cbo-object', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    resolve(null)
                } else {
                    resolve(result.data);
                }
            });
    })

}

export function getCboDivision(param) {
    return new Promise((resolve) => {
        Api.put('/w18f4070/get-division', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    resolve(null)
                } else {
                    resolve(result.data);
                }
            });
    })

}


export function getCboDepartment(param, cb) {
    return () => {
        Api.put('/w18f4070/load-cbo-sales-group', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

export function cboSalePersonWin(param, cb) {
    Api.put('/w18f4070/load-cbo-sales-person', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });

}

export function getCustomerLevel(cb) {
    return (dispatch) => (
        Api.get('/w20f1002/get-customer-level')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch({
                        type: types.TEMPLATE_DROPDOWN_CUSTOMERLEVEL_COMPLETE,
                        cboCustomerLevel: result.data
                    })

                }
            })
    )
}

export function getCompanyStatus(cb) {
    return (dispatch) => (
        Api.get('/w20f1002/get-company-status')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch({
                        type: types.TEMPLATE_DROPDOWN_COMPANYSTATUS_COMPLETE,
                        cboCompanyStatus: result.data
                    })

                }
            })
    )
}
