/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/08/23 21:26
 * @update 2019/08/23 21:26
 * @file src/actions/loading-action.js
 */

import * as types from './types';
import Api from '../services/api';
import Config from '../config';
import loadLocalize from './system/loading-localize';

async function checkMaintenance () {
    try {
        let res = await Api.get(`/deploy/check?AppID=${Config.env.productID}`);
        return res?.data || null;
    } catch (e) {
        console.log('deploy/check is error');
        return null;
    }
}
function getSettingList(dispatch) {
    Api.put('/setting/load')
        .then(async settingLists => {
            // console.log("================================ settingLists:", settingLists);

            Config.setting = settingLists.data;
            Config.setLocalStorage('SETTING', JSON.stringify(Config.setting));

            Config.profile = JSON.parse(Config.decryptData(Config.getLocalStorage('PROFILE')));
            Config.language = Config.getLocalStorage('langCRM')==='en' ? "01":"84" ;
            //check maintenance..
            const maintenance = await checkMaintenance();
            // const maintenance = {
            //     "from": "2021-06-29 08:00:00",
            //     "to": "2021-06-29 15:00:00"
            // };
            dispatch(settingListComplete({token: Config.token, setting: Config.setting, profile: Config.profile,language:Config.language, maintenance}));
        })
        .catch(settingError => {

            if (!settingError || !settingError.code) return false;

            Config.removeLocalStorage('TOKEN');
            Config.removeLocalStorage('SETTING');
        });
}

export function loading() {
    const time = new Date().getTime();
    return async (dispatch) => {
        await loadLocalize();

        let tokenStore = Config.getLocalStorage('TOKEN', false, false);
        let settingStore = Config.getLocalStorage('SETTING');
        let profileStore = Config.decryptData(Config.getLocalStorage('PROFILE'));

        if(tokenStore && Config.isJson(tokenStore)) tokenStore = JSON.parse(tokenStore);
        if (tokenStore && tokenStore.id && profileStore && settingStore ) {
            settingStore = JSON.parse(settingStore);
            profileStore = JSON.parse(profileStore);

            const expire = tokenStore.expire || 0;
            // console.log('===== expire: ', expire);
            // console.log('===== time: ', time);
            if (expire > time) {
                Config.token = tokenStore;
                Config.setting = settingStore;
                Config.profile = profileStore;
                Config.language = Config.getLocalStorage('langCRM')==='en' ? "01":"84" ;
                const maintenance = await checkMaintenance();
                // const maintenance = {
                //     "from": "2021-06-29 08:00:00",
                //     "to": "2021-06-29 15:00:00"
                // };
                dispatch(settingListComplete({token: Config.token, setting: Config.setting, profile: Config.profile,language:Config.language, maintenance}));
            } else {
                Config.getToken = true;
                // console.log('===== token: ', Config.token.id);

                Api.put('/auth/check', {token: tokenStore.id})
                    .then(updateToken => {
                        const expire = time + 7 * 24 * 60 * 60 * 1000;

                        Config.getToken = false;
                        Config.token = {
                            id: updateToken.data.token,
                            expire: expire
                        };

                        Config.setLocalStorage('TOKEN', JSON.stringify(Config.token));
                        getSettingList(dispatch);
                    })
                    .catch(updateTokenError => {

                        if (!updateTokenError || !updateTokenError.code) return false;

                        Config.removeLocalStorage('TOKEN');
                        Config.removeLocalStorage('SETTING');
                    });
            }
        }
        else {
            Config.getToken = true;

            Api.get('/auth/token')
                .then(tokenResult => {
                    // console.log("================================ auth/token:", tokenResult);

                    const expire = time + 7 * 24 * 60 * 60 * 1000;

                    Config.getToken = false;
                    Config.token = {
                        id: tokenResult.token,
                        expire: expire
                    };

                    Config.setLocalStorage('TOKEN', JSON.stringify(Config.token));

                    getSettingList(dispatch);
                })
                .catch(tokenError => {

                    if (!tokenError || !tokenError.code) return false;

                    Config.removeLocalStorage('TOKEN');
                    Config.removeLocalStorage('SETTING');
                });

            Config.getToken = false;
        }
    }
}

export function settingListComplete(data) {

    return {
        type: types.GET_SETTING_COMPLETE,
        token: data.token,
        setting: data.setting,
        profile: data.profile,
        language:data.language,
        maintenance:data.maintenance,
    }
}
