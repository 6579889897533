/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 03/01/2021
 * @update 03/01/2021
 */

import Api from '../../../services/api.js';
export default async (param) => {
    return new Promise((resolve, reject) => {
        Api.put('/w17f2042/load-drd-unit', param)
            .then(result => {
                if (result && result.code && result.message) {
                    return resolve(0);
                } else {
                    return resolve(result);
                }
            });
    });
};
