/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 20/01/2021
 * @update 05/03/2021
 */
import Api from "../../../services/api";

//loadDataInventory
export function loadDataInventory(param, cb) {
    return async () => {
        Api.put('/w17f2030/load-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//Save Data W17F2030
export function saveW17F2030(param, cb) {
    return async () => {
        Api.put('/w17f2030/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Reload Grid after chosen W17F2030
export function reLoadGrid(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//loadDataInventory W17F2040
export function reLoadGridW17F2040(param, cb) {
    return async () => {
        Api.put('/w17f2040/load-grid-inventory', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}