/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/14 17:37
 * @update 2019/02/14 17:37
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function loadW18F1010(param, cb) {
    return (dispatch) => {
        Api.put('/w18/w18f1010', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadW18F1010Complete(result.data));
                }
            });
    };
}

function loadW18F1010Complete(data) {
    return {
        type: types.W18_W18F1010_COMPLETE,
        loadW18F1010: data
    };
}

export function delW18F1010(param, cb) {
    // Delete other table too
    return (dispatch) => {
        Api.put('/w18f1010/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(delW18F1010Complete(result.data));
                }
            });
    };
}

function delW18F1010Complete(data) {
    return {
        type: types.W18_W18F1010_DEL_COMPLETE,
        delW18F1010: data
    };
}

export function loadW18F1010fromStore(param, cb) {
    // Delete other table too
    return (dispatch) => {
        Api.put('/w18f1010/load-store-W17P1010', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadW18F1010fromStoreComplete(result.data));
                }
            });
    };
}

export function clearW18F1010(){
    return (dispatch) =>{
        dispatch({
            type: types.W18F1010_STORE_COMPLETE,
            loadW18F1010Store: null
        })
    }
}

function loadW18F1010fromStoreComplete(data) {
    return {
        type: types.W18F1010_STORE_COMPLETE,
        loadW18F1010Store: data
    };
}


export function loadFilter(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1010/load-store-W17P1019', param)
            .then(result => {
                // console.log('== loadFilter => result:', result);

                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadFilterComplete(result.data));
                }
            });
    };
}

function loadFilterComplete(data) {
    return {
        type: types.W18_W18F1010_FILTER_COMPLETE,
        dataFilter: data
    };
}


export function exportExcelTemplateURL(param,cb) {
    const sql = `SELECT	TOP 1 URL FROM D89T1000 WITH(NOLOCK) WHERE Disabled = 0 AND IsLW = 1 AND ModuleID = '17' AND ReportTypeID = '${param.ReportTypeID?param.ReportTypeID:''}' AND ReportID='${param.ReportID?param.ReportID:''}' AND IsLW = 1;`;
    return () => {
        Api.put('/mssql/query', {sql: sql})
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

