import * as types from "../actions/types"
import Api from "../services/api";

export function getList(param, cb) {
    return (dispatch) => {
        Api.put('/W18F1060/get-list', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function getListNew(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1060/get-list-new', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function getComboStatus(param, cb) {
    return (dispatch) => {
        Api.put('/W18F1060/get-combo-status', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getComboStatusComplete(result.data))
                }
            });
    };
}

function getComboStatusComplete(data) {
    return {
        type: types.W18_W18F1060_GET_COMBO_STATUS,
        getComboStatus: data
    }
}

export function getComboFeedback(param,cb) {
    return (dispatch) => {
        Api.put('/W18F1060/get-combo-feedback', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getComboFeedbackComplete(result.data))
                }
            });
    };
}

function getComboFeedbackComplete(data) {
    return{
        type: types.W18_W18F1060_GET_COMBO_FEEDBACK,
        getComboFeedback: data
    }
}

export function getCaseType(param,cb) {
    return (dispatch) => {
        Api.put('/w18f1061/get-case-type', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCaseTypeComplete(result.data))
                }
            });
    };
}

function getCaseTypeComplete(data) {
    return{
        type: types.W18_W18F1060_GET_CASE_TYPE,
        getCaseType: data
    }
}

export function deleteRowData(param,cb) {
    return (dispatch) => {
        Api.put('/W18F1060/delete', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(deleteComplete(result.data))
                }
            });
    };
}

function deleteComplete(data) {
    return {
        type: types.W18_W18F1060_DELETE,
        deleteData: data
    }
}

export function addHistory(param,cb){
    //Lưu lịch sử riêng với lưu form master
    return async () => {
        await Api.put('/history/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                    return true;
                } else {
                    if (cb) cb(null, result.data);
                    return true;
                }
            });
    };
}