/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/06/06 10:37
 * @file src/actions/device-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function addDevice(param, cb) {
    return (dispatch) => {
        // console.log("============== listSetting -> param: ", param);

        Api.put('/device/add', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result);
                    dispatch(addDeviceComplete(result));
                }
            });
    };
}

function addDeviceComplete(data) {
    return {
        type: types.ADD_DEVICE_COMPLETE,
        addDevice: data
    };
}