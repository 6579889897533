/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */

import Api from '../../../services/api.js';

export function getDataGrid(param, cb) {
    Api.put(`/w20f1003/search`, param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}

export function saveData(param, cb) {
    Api.put(`/w20f1003/add`, param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}
