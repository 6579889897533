import Api from "../services/api";
import * as types from "./types";

export function getFilter(param, cb) {
    //Danh sách bộ lọc
    return () => {
        Api.put('/W18F1070/get-list-of-filters', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    }
}

export function dataProcess(param, mode, cb) {
    //Dùng chung cho add edit, del
    Api.put(`/W18F1070/${mode}`, param)
        .then(result => {
            if (result && result.code && result.message) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result);
            }
        });
}

export function getPopOverFilter(param, cb) {
    //thêm điều kiện lọc
    return (dispatch) => {
        Api.put('/W18F1070/load-popup-add-filters', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(dispatchComplete(result.data, 'getPoPFilter'));
                }
            });
    }
}

export function getDefaultFilter(cb) {
    // bộ lọc mặc định
    return (dispatch) => {
        Api.put('/W18F1070/get-default-filter-details')
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(dispatchComplete(result.data, 'getDefaultFilter'));
                }
            });
    }

}

export function getFilterDetail(param, cb) {
    // đổ nguồn khi click edit
    Api.put('/W18F1070/get-filter-details', param)
        .then(result => {
            if (result && result.code && result.message) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });

}

export function getDynamicDropdown(url, cb) {
    Api.put(`/${url}`)
        .then(result => {
            if (result && result.code && result.message) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });

}

export function checkIsManager(cb) {
    return (dispatch) => {
        Api.put(`/w18f1070/check-is-manager`)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dispatchComplete(result.data, 'checkIsManager'))
                }
            });
    }

}

function dispatchComplete(data, type) {
    switch (type) {
        case 'getDefaultFilter':
            return {
                type: types.LIST_DEFAULT_FILTER_ADVANCED_SEARCH,
                listDefaultFilter: data
            };
        case'getPoPFilter':
            return {
                type: types.POPOVER_FILTER_DETAIL_ADVANCED_SEARCH,
                popOverFilter: data
            };
        case 'checkIsManager':
            return {
                type: types.CHECK_MANAGER_ADVANCED_SEARCH,
                isManager: data
            };
        default:
            return null


    }
}