/**
 * @copyright 2020 @ DigiNet
 * @author TaiAu
 * @create 2020/10/26
 * @update 2020/10/26 
 */
import Api from "../../../services/api";

export function getCboObject(param, cb) {
    return (dispatch) => {
        Api.put('/w18f4080/load-cbo-object', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}
