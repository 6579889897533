/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */

import * as types from '../../types.js';
import Api from '../../../services/api.js';


export function getCboEvaluatetypeW19F1031(cb){
        return (dispatch)=>{
            Api.put('/W19F1031/cbo-evaluate-add-form')
                .then(result => {
                    if (result && result.code && result.code !== 200) {
                        if (cb) cb(result, null);
                    } else {
                        if (cb) cb(null, result.data);
                        dispatch(getCboEvaluatetypeW19F1031Complete(result.data));

                    }
                });
        }
}
function getCboEvaluatetypeW19F1031Complete(data) {
    return {
        type: types.W19_W19F1031_CBOEVALUATE_COMPLETE,
        cboEvaluate: data
    };
}

export function dataMasterW19F1031(param, cb) {
    return () => {
        Api.put('/W19F1031/grid-edit-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}



export function saveDataW19F1031(param, cb) {
    return () => {
        Api.put('/W19F1031/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
export function editDataW19F1031(param, cb) {
    return () => {
        Api.put('/W19F1031/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadHistory(param,cb){
    return () => {
        Api.put('/history/search', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}
export function addHistory(param,cb){
    //Lưu lịch sử riêng với lưu form master
    return async () => {
         await Api.put('/history/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                    return true;
                } else {
                    if (cb) cb(null, result.data);
                    return true;

                }
            });
    };
}

export function getAttach(param, cb) {
    return () => {
        Api.put('/w19f1031/data-by-key-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}