/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/09/20 11:00
 * @update 2019/09/20 11:00
 */

import Api from "../../services/api";
import * as types from "../types";

export function loadCBStatus(param, cb) {
    return (dispatch) => {
        Api.put('/W20F1000/get-cbo-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCBStatusComplete(result.data));
                }
            });
    };
}


function loadCBStatusComplete(data) {
    return {
        type: types.W20_W20F1000_CB_STATUS_COMPLETE,
        loadCBStatus: data
    };
}

export function loadMaster(param, cb) {
    return (dispatch) => {
        Api.put('/W20F1000/get-list-master', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadMasterComplete(result.data));
                }
            });
    };
}


function loadMasterComplete(data) {
    return {
        type: types.W20_W20F1000_LOAD_MASTER_COMPLETE,
        loadMaster: data
    };
}

export function loadTotal(param, cb) {
    return (dispatch) => {
        Api.put('/W20F1000/get-total-company', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadTotalComplete(result.data));
                }
            });
    };
}


function loadTotalComplete(data) {
    return {
        type: types.W20_W20F1000_LOAD_MASTER_COMPLETE,
        loadTotal: data
    };
}

export function loadDetail(param, cb) {
    return (dispatch) => {
        Api.put('/W20F1000/get-list-detail', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDetailComplete(result.data));
                }
            });
    };
}

export function deleteRow(param, cb) {
    return () => {
        Api.put('/W20F1000/delete', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

function loadDetailComplete(data) {
    return {
        type: types.W20_W20F1000_LOAD_MASTER_COMPLETE,
        loadDetail: data
    };
}

export function deleteRowCustomer(param, cb) {
    return () => {
        Api.put('/W20F1000/delete-customer', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

export function getContactGrid(param, cb) {
    return () => {
        Api.put('/W20F1000/get-list-contact', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

export function deleteContact(param, cb) {
    return () => {
        Api.put('/W20F1000/delete-contact', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}


export function updateStatus(param,cb){
    Api.put('/W20F1000/update-status', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}


export function copyData(param,cb){
    Api.put('/W20F1000/copy', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
}

export function getCboGender(param, cb) {
    return (dispatch) => {
        Api.put('/W20F1000/get-cbo-gender', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboGenderComplete(result.data));
                }
            });
    }
}

function getCboGenderComplete(data) {
    return {
        type: types.W20_W20F1000_CBO_GENDER_COMPLETE,
        dataCboGender: data
    };
}