/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/w18F1020.js
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function apiQuery(param, mode, cb) {
    //API  dung  de query  sql
    return (dispatch) => {
        Api.put('/mssql/query', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(apiQueryComplete(result.data, mode));
                }
            });
    };
}


function apiQueryComplete(data, mode) {
    switch (mode) {
        // case 'GROUPSALE':
        //     return {
        //         type: types.W18_W18F1020_CBO_GROUPSALE_COMPLETE,
        //         cboGroupSaleW18F1020: data
        //     };
        case 'SALEPERSON':
            return {
                type: types.W18_W18F1020_CBO_SALEPERSON_COMPLETE,
                cboSalePersonW18F1020: data
            };
        case 'TYPETIME':
            return {
                type: types.W18_W18F1020_CBO_TYPETIME_COMPLETE,
                cboTypeTimeW18F1020: data
            };
        // case 'GRID':
        //     return {
        //         type: types.W18_W18F1020_GRID_COMPLETE,
        //         loadGridW18F1020: data
        //     };
        // case 'DETAIL':
        //     return {
        //         type: types.W18_W18F1020_DETAIL_COMPLETE,
        //         loadDetailW18F1020: data
        //     };

        default :
            break;
    }

}

export function cboGroupSaleW18F1020(param, cb) {
    return (dispatch) => {
        Api.put('/dropDownSaleGroup/data-by-mode', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboGroupSaleW18F1020Complete(result.data));
                }
            });
    };
}

function cboGroupSaleW18F1020Complete(data) {
    return {
        type: types.W18_W18F1020_CBO_GROUPSALE_COMPLETE,
        cboGroupSaleW18F1020: data
    };
}

export function loadGridW18F1020(param, cb) {
    return (dispatch) => {
        Api.put('/DashBoard/data-f-W17P1040', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridW18F1020Complete(result.data));
                }
            });
    };
}

function loadGridW18F1020Complete(data) {
    return {
        type: types.W18_W18F1020_GRID_COMPLETE,
        loadGridW18F1020: data
    };
}

export function loadDetailW18F1020(param, cb) {
    return (dispatch) => {
        Api.put('/DashBoard/data-f-W17P1018', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDetailW18F1020Complete(result.data));
                }
            });
    };
}

function loadDetailW18F1020Complete(data) {
    return {
        type: types.W18_W18F1020_DETAIL_COMPLETE,
        loadDetailW18F1020: data
    };
}

export function SCHEDULE(param, cb) {
    return (dispatch) => {
        Api.put('/scheduleTemplate/load-store-W17P1040', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(SCHEDULEComplete(result.data));
                }
            });
    };
}

function SCHEDULEComplete(data) {
    return {
        type: types.W18_W18F1020_SCHEDULE_COMPLETE,
        scheduleData: data
    };
}



// export function apiStore(param, mode, cb) {
//     //API su dung store cua sql
//     return (dispatch) => {
//         Api.put('/scheduleTemplate/load-store-W17P1040', param)
//             .then(result => {
//                 if (result && result.code && result.code !== 200) {
//                     if (cb) cb(result, null);
//                 } else {
//                     if (cb) cb(null, result.data);
//                     dispatch(apiStoreComplete(result.data, mode));
//                 }
//             });
//     };
// }
//
//
// function apiStoreComplete(data, mode) {
//     switch (mode) {
//         case 'SCHEDULE':
//             return {
//                 type: types.W18_W18F1020_SCHEDULE_COMPLETE,
//                 scheduleData: data
//             };
//
//         default:
//             return null
//     }
//
// }


export function deleteTask(param, cb) {
    return () => {
        Api.put('/w18f1014/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}