/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function dataAttachedW18F1014(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1014/data-by-key-id', param)
            .then(result => {
                // console.log("result result",result)
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dataAttachedW18F1014Complete(result.data));
                }
            });
    };
}

function dataAttachedW18F1014Complete(data) {
    return {
        type: types.W18_W18F1014_DATAATTACH_COMPLETE,
        dataAttachedW18F1014: data
    };
}

export function cboContactW18F1014(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1014/data-by-company-id', param)
            .then(result => {
                // console.log("result result",result)
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboContactW18F1014Complete(result.data));
                }
            });
    };
}

function cboContactW18F1014Complete(data) {
    return {
        type: types.W18_W18F1014_CBOCONTACT_COMPLETE,
        cboContactW18F1014: data
    };
}

export function cboPriorityW18F1014(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1014/data-w-priority', param)
            .then(result => {
                // console.log("result result",result)
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboPriorityW18F1014Complete(result.data));
                }
            });
    };
}

function cboPriorityW18F1014Complete(data) {
    return {
        type: types.W18_W18F1014_CBOPRIORITY_COMPLETE,
        cboPriorityW18F1014: data
    };
}

export function cboTaskStatusW18F1014(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1014/data-w-status', param)
            .then(result => {
                // console.log("result result",result)
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboTaskStatusW18F1014Complete(result.data));
                }
            });
    };
}

function cboTaskStatusW18F1014Complete(data) {
    return {
        type: types.W18_W18F1014_CBOTASKSTATUS_COMPLETE,
        cboTaskStatusW18F1014: data
    };
}

export function cboTaskTypeW18F1014(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1014/data-by-item-type', param)
            .then(result => {
                // console.log("result result",result)
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboTaskTypeW18F1014Complete(result.data));
                }
            });
    };
}

function cboTaskTypeW18F1014Complete(data) {
    return {
        type: types.W18_W18F1014_CBOTASKTYPE_COMPLETE,
        cboTaskTypeW18F1014: data
    };
}

export function addNewTask(param,cb){
    return ()=>{
        Api.put('/w18f1014/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function editTask(param,cb){
    return ()=>{
        Api.put('/w18f1014/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function clearAttachment() {
    return (dispatch) => {
        dispatch({
            type: types.W18_W18F1014_DATAATTACH_COMPLETE,
            dataAttachedW18F1014: null
        });
    };

}

export function getListComment(param,cb){
    return ()=>{
        Api.put('/comment/get-list', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function removeComment(param,cb){
    return ()=>{
        Api.put('/comment/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function editComment(param,cb){
    return ()=>{
        Api.put('/comment/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function addComment(param,cb){
    return ()=>{
        Api.put('/comment/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function getForm(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1014/get-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
