/**
 * @copyright 2023   @ DigiNet
 * @author ANHTAI
 * @create 2023/06/21 15:37
 * @update 2023/06/21 15:37
 * @file src/actions/user-actions.js
 */

import Api from '../services/api';

export async function uploadCDN_sync(data, mode, tokenCDN) {
    return new Promise((resolve) => {
        const body = new FormData();
        if (mode === 'multiple') {
            for (let i = 0; i < data.length; i++)
                body.append('files', data[i]);
        }
        else {
            body.append('files', data);
        }
        Api.putCDN('/file/upload', body, tokenCDN)
            .then(result => {
                return resolve(result);
            });
    });
};

export function getTokenCDN() {
    return new Promise((resolve) => {
        Api.getCDN('/auth/token')
            .then(result => {
                return resolve(result);
            });
    });
};