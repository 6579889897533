/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function loadList(param, cb) {
    return (dispatch) => {
        Api.put('/W17F0043/get-report', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(apiQueryComplete(result.data, 'ReportList'));
                }
            });
    };
}

function apiQueryComplete(data, mode) {
    switch (mode) {
        case 'ReportList':
            return {
                type: types.W17F0043_LOAD_REPORT_LIST_COMPLETE,
                listReport: data
            };
        default:
            return null;

    }

}

export function addW17F0041(param, cb) {
    return (dispatch) => {
        Api.put('/D89T1000/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(addW17F0041Complete(result));
                }
            });
    };
}

export function updateW17F0043(param, cb) {
    return (dispatch) => {
        Api.put('/W17F0043/update', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(updateW17F0043Complete(result));
                }
            });
    };
}

function updateW17F0043Complete(data) {
    return {
        type: types.W17F0043_UPDATE_COMPLETE,
        updateW17F0043: data
    };
}

function addW17F0041Complete(data) {
    return {
        type: types.W17_W17F0041_ADD_COMPLETE,
        addW17F0041: data
    };
}

export function editW17F0041(param, cb) {
    return (dispatch) => {
        Api.put('/D89T1000/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(editW17F0041Complete(result));
                }
            });
    };
}
function editW17F0041Complete(data) {
    return {
        type: types.W17_W17F0041_EDIT_COMPLETE,
        editW17F0041: data
    };
}

export function delW17F0041(param, cb) {
    return (dispatch) => {
        Api.put('/D89T1000/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW17F0041Complete(result));
                }
            });
    };
}
function deleteW17F0041Complete(data) {
    return {
        type: types.W17_W17F0041_DELETE_COMPLETE,
        delW17F0041: data
    };
}
