/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/14 17:37
 * @update 2019/02/14 17:37
 */

import * as types from '../../types.js';
import Api from '../../../services/api.js';


//cbo
export function getCboStatus(param, cb) {

    return (dispatch) => {
        Api.get('/w17f2025/load-combo-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch({
                        type: types.W17F2025_GET_CBO_STATUS_COMPLETE,
                        data: result.data
                    });
                }
            });
    };
}
export function getCboSalesPerson(param, cb) {

    return (dispatch) => {
        Api.put('/w17f2025/load-combo-sales-person', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch({
                        type: types.W17F2025_GET_CBO_SALES_PERSON_COMPLETE,
                        data: result.data
                    });
                }
            });
    };
}
export function getCboCaseSource(param, cb) {

    return (dispatch) => {
        Api.get('/w17f2025/load-combo-case-source', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch({
                        type: types.W17F2025_GET_CBO_CASE_SOURCE_COMPLETE,
                        data: result.data
                    });
                }
            });
    };
}
export function getCboCollaborator(param, cb) {

    return (dispatch) => {
        Api.get('/w17f2025/load-combo-collaborator', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch({
                        type: types.W17F2025_GET_CBO_COLLABORATOR_COMPLETE,
                        data: result.data
                    });
                }
            });
    };
}
export function getNewKey(param, cb) {

    return (dispatch) => {
        Api.put('/w17f2025/get-new-key', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    
                }
            });
    };
}
export function getForm(param, cb) {

    return (dispatch) => {
        Api.put('/w17f2025/load-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch({
                        type: types.W17F2025_GET_FORM_COMPLETE,
                        data: result.data
                    });
                }
            });
    };
}
export function checkEdit(param, cb) {

    return (dispatch) => {
        Api.put('/w17f2025/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    
                }
            });
    };
}
export function add(param, cb) {

    return (dispatch) => {
        Api.post('/w17f2025/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    
                }
            });
    };
}
export function edit(param, cb) {

    return (dispatch) => {
        Api.put('/w17f2025/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    
                }
            });
    };
}
export function getAttachments(param, cb) {

    return (dispatch) => {
        Api.put('/attachment/get-attachment', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    
                }
            });
    };
}

export function getDMFSetting(param, cb) {

    return (dispatch) => {
        Api.get('/w17f2000/get-dmf-setting', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    
                }
            });
    };
}

