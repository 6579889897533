import Api from "../services/api";
import * as types from "./types";

/**
 * @copyright 2019 @ DigiNet
 * @author KIMLONG
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/W17f1002-actions.js
 */

export function loadContentMail(params, cb) {
    return () => {
        Api.put('/w17f1002/load-default-mail', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function sendMail(param, cb) {
    //API  dung  de query  sql
    return (dispatch) => {
        let body = new FormData();

        body.append('emailTo', param.emailTo);
        body.append('emailCC', param.emailCC);
        body.append('emailBCC', param.emailBCC);
        body.append('subject', param.subject);
        body.append('content', param.content);

        body.append('attachmentsURL', param.attachmentsURL);
        for(let i=0;i< param.attachments.length;i++){
            body.append('attachments', param.attachments[i]);
        }
        Api.upload('/mailer/mail', body)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(sendMailComplete(result.data));
                }
            });
    };
}

function sendMailComplete(data) {
    return {
        type: types.W17_W17F1002_SENDMAIL_COMPLETE,
        sendMail: data
    };
}

export function saveMail(param, cb) {
    return (dispatch) => {
        Api.put('/w17f0002/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(saveMailComplete(result.data));
                }
            });
    };
}


function saveMailComplete(data) {
    return {
        type: types.W17_W17F1002_SAVEMAIL_COMPLETE,
        saveMail: data
    };
}

