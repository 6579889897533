/**
 * @copyright 2022 @ DigiNet
 * @author TRIHAO
 * @create 7/11/2022
 * @Example
 */
import Api from "../../../services/api";

// Combo Mail Services W17F0060
export function getCbo(param, cb) {
    return dispatch => {
        Api.put("/w17f0060/load-cbo", param).then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}

//Save Data W17F2020
export function save(param, cb) {
    return async () => {
        Api.put("/w17f0060/save", param).then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
