/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/actions/file-actions.js
 */

import Api from '../services/api.js';

export function upload(data,mode,cb) {
    return(dispatch)=> {
        var body = new FormData();
        if(mode==='multiple'){
            for(let i=0;i<data.length;i++)
                body.append('files', data[i]);
        }
        else{
            body.append('files', data);
        }
        Api.upload('/file/upload',body)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    }
}
