/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';
export function deleteNorm(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function getDetailW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1010/get', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(getDetailCompleteW18F1011(result.data));
                }
            });
    };
}


function getDetailCompleteW18F1011(data) {
    return {
        type: types.W18_W18F1011_DATA_MASTER_COMPLETE,
        dataMasterW18F1011: data
    };
}

export function checkValidate(param,  cb) {
    return () => {
        Api.put('/mssql/query', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data[0]);
                }
            });
    };
}

export function loadDataW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/load-store-w17p1011', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadDataW18F1011Complete(result.data));
                }
            });
    };
}


function loadDataW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_GRID_COMPLETE,
        dataGridW18F1011: data
    };
}

