/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 20/11/2020
 */

import _ from "lodash";
import Api from "../../../services/api";
import * as types from "../../types";


export function getGroupList(param, cb) {
    return (dispatch) => {
        Api.put('/w94f1000/get-reports', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    if (param && _.isEmpty(param.MReportID)) dispatch(dispatchComplete(result.data, 'getGroupList'));
                }
            });
    }
}

export function getControlMaster(param, cb) {
    return (dispatch) => {
        Api.put('/w94f1002/get-controls', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

export function getControlDetailReport(param, cb) {
    return (dispatch) => {
        Api.put('/w94f1002/get-controls-detail', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}

function dispatchComplete(data, mode) {
    if (mode === 'getGroupList') {
        if (data.constructor.name === "Object") data = [data];
        return {
            type: types.W94_W94F1000_GET_GROUP_LIST_COMPLETE,
            data
        }
    }
}
