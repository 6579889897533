/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadTree(param, cb) {
    //do nguon luoi
    return (dispatch) => {
        Api.put('/w18f1023/load-store-W17P9000', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadTreeComplete(result.data));
                }
            });
    };
}


function loadTreeComplete(data) {
    return {
        type: types.W18_W18F1023_LOADTREE_COMPLETE,
        treeData: data
    };

}

export function transferSale(params, cb) {
    return () => {
        Api.put('/w17f1025/transfer-sale', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}



