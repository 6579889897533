/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 7/27/2020
 * @Example 
 */

import Api from '../../services/api.js';
export default async (param)=> {
    return new Promise((resolve, reject) => {
        Api.put('/export/passing-parameter', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    return reject(result);
                } else {
                    return resolve(result.data);
                }
            });
    });
};
