/**
 * @copyright 2019 @ DigiNet
 * @author thanhtram
 * @create 2019/03/04 11:01
 * @update 2019/03/04 11:01
 */

import Api from '../services/api.js';

export default async (param)=> {
    return new Promise((resolve, reject) => {
        Api.put('/w18f1031/del', param)
            .then(result => {
                return resolve(result);
            });
    } );
};