/**
 * @copyright 2021 @ DigiNet
 * @author thanhHai
 * @create 15/3/2021
 * @update 15/3/2021
 */

import Api from '../../../services/api';
import * as types from '../../types'

export function getCboCategoryType(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f2051/Load-combo-category', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F2051_GET_CBO_CATEGORY_TYPE_COMPLETE,
                    data: result.data
                });
            }
        });
    };
}
export function getCboGender(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f2051/Load-combo-gender', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F2051_GET_CBO_GENDER_COMPLETE,
                    data: result.data
                });
            }
        });
    };
}
export function getAnaMaster(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f2051/load-ana-category', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F2051_GET_ANA_MASTER_COMPLETE,
                    data: result.data
                });
            }
        });
    };
}
export function getAnaMasterCaption(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f2051/load-ana-category-use', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F2051_GET_ANA_MASTER_CAPTION_COMPLETE,
                    data: result.data
                });
            }
        });
    };
}
export function getAdditionalMasterCaption(param, cb) {
    return (_dispatch) => {
        Api.get('/w17f2051/load-caption-data', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
               
            }
        });
    };
}
export function getForm(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f2051/load-form', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                _dispatch({
                    type: types.W17F2051_LOAD_FORM_MASTER_COMPLETE,
                    data: result.data
                });
            }
        });
    };
}
export function checkEdit(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f2051/check-edit', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
            }
        });
    };
}
export function getNewKey(param, cb) {
    return (_dispatch) => {
        Api.put('/w17f2051/new-key', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
                
            }
        });
    };
}
export function save(param, cb) {
    return (_dispatch) => {
        Api.post('/w17f2051/save', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result.data);
               
            }
        });
    };
}


