import Api from "../../../services/api";
import * as types from "../../types";

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 15:37
 * @update 2019/03/28 15:37
 */

export function loadData(param, cb) {
    return (dispatch) => {
        dispatch({
            type: types.W17_W17F0050_DATAGRID_COMPLETE,
            dataGrid: []
        });
        Api.put('/template/search', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDataComplete(result));

                }
            });
    };
}


function loadDataComplete(data) {
    return {
        type: types.W17_W17F0050_DATAGRID_COMPLETE,
        dataGrid: data
    };
}

export function deleteTemplate(param, cb) {
    return () => {
        Api.put('/template/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);

                }
            });
    };
}