/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 15:37
 * @update 2019/03/28 15:37
 */
import Api from "../../../services/api";
import * as types from "../../types";

export function loadGrid(param, cb) {
    return () => {
        Api.put('/w18f4040/load-store-W17P4041', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    // dispatch(dispatchComplete(result.data,'loadGrid'));
                }
            });
    };
}

export function getDecimal(param, cb) {
    return (dispatch) => {
        Api.put('/w18f4040/get-quantity-decimals', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dispatchComplete(result.data,'getDecimal'));
                }
            });
    };
}

function dispatchComplete(data,mode){
    if(mode==='getDecimal'){
        return {
            type:types.W18_W18F4040_GETDECIMAL_COMPLETE,
            dataDecimal:data
        }
    }
}