/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/14 17:37
 * @update 2019/02/14 17:37
 */

import * as types from './../../types.js';
import Api from '../../../services/api.js';



export function delW17F1025(param, cb) {
    // Delete other table too
    return (dispatch) => {
        Api.put('/w17f1025/confirm', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(delW17F1025Complete(result.data));
                }
            });
    };
}

function delW17F1025Complete(data) {
    return {
        type: types.W17_W17F1025_DEL_COMPLETE,
        delW17F1025: data
    };
}

export function loadW17F1025fromStore(param, cb) {
    // Delete other table too
    return (dispatch) => {
        Api.put('/w17f1025/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadW17F1025fromStoreComplete(result.data));
                }
            });
    };
}

export function clearW17F1025(){
    return (dispatch) =>{
        dispatch({
            type: types.W17F1025_STORE_COMPLETE,
            loadW17F1025Store: null
        })
    }
}

function loadW17F1025fromStoreComplete(data) {
    return {
        type: types.W17F1025_STORE_COMPLETE,
        loadW17F1025Store: data
    };
}


export function loadFilter(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1010/load-store-W17P1019', param)
            .then(result => {
                // console.log('== loadFilter => result:', result);

                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadFilterComplete(result.data));
                }
            });
    };
}

function loadFilterComplete(data) {
    return {
        type: types.W17_W17F1025_FILTER_COMPLETE,
        dataFilter: data
    };
}
    

export function exportExcelTemplateURL(param,cb) {
    const sql = `SELECT	TOP 1 URL FROM D89T1000 WITH(NOLOCK) WHERE Disabled = 0 AND IsLW = 1 AND ModuleID = '17' AND ReportTypeID = '${param.ReportTypeID?param.ReportTypeID:''}' AND ReportID='${param.ReportID?param.ReportID:''}' AND IsLW = 1;`;
    return () => {
        Api.put('/mssql/query', {sql: sql})
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function checkDel(param, cb) {
    return (dispatch) => {
        Api.delete('/w17f1025/delete', param)
            .then(result => {
                // console.log('== loadFilter => result:', result);

                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function checkEdit(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1025/check-edit', param)
            .then(result => {
                // console.log('== loadFilter => result:', result);

                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}