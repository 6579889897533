/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/actions/user-actions.js
 */

import Api from '../services/api.js';

export default async (file,param)=> {
    return new Promise((resolve, reject) => {
        const body = new FormData();
        body.append('params',param ? JSON.stringify(param): null );
        body.append('files', file);
        Api.upload('/w17f0001/add-post', body)
            .then(result => {
                return resolve(result);
            });
    } );
};