import Api from "../../../services/api";

// import * as types from "../../types";

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 15:37
 * @update 2019/03/28 15:37
 */

export function loadData(param, cb) {
    return () => {
        Api.put('/template/get', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadParamField(param, cb) {
    return () => {
        Api.put('/template-param/search', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function saveData(param, mode, cb) {
    return () => {
        Api.put(`/template/${mode}`, param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}


