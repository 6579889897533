/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 4/10/2019
 * W17F1520 W17F1521
 */
import Api from "../../../services/api";

export function saveData(param,mode,cb){
    return ()=>{
        Api.put(`/w17f1521/${mode}`, param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    }
}






