/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/actions/user-actions.js
 */

import Api from '../services/api.js';

export default async (param)=> {
    return new Promise((resolve, reject) => {
        Api.put('/w17f0001/add', param)
            .then(result => {
                return resolve(result);
            });
    } );
};