/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function loadGridW18F1015(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1015/load-store-w17p1010', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridW18F1015Complete(result.data));
                }
            });
    };
}

function loadGridW18F1015Complete(data) {
    return {
        type: types.W18_W18F1015_DATAGRID_COMPLETE,
        dataGrid: data
    };
}
export function loadGridW17F1025(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1025/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridW18F1015Complete(result.data));
                }
            });
    };
}

export function checkOldCRM(param, cb) {
    return (dispatch) => {
        Api.get('/w19f1000/check-old-crm', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadRefreshInventory(param, cb) {
    return () => {
        Api.put('/w17f2040/refresh-inventory', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}