/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';
import Config from "../config";

export function getAuth(param, cb) {
    return () => {
        // console.log("============== getUser -> param: ", param);

        Api.put('/auth/link', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                }
            });
    };
}

export function login(param, cb) {
    return (dispatch) => {
        // console.log("============== getUser -> param: ", param);

        Api.put('/user/login', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                }
            });
    };
}

export function getUser(param, cb) {
    return (dispatch) => {
        // console.log("============== getUser -> param: ", param);

        Api.put('/user/get', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(getUserComplete(result.data));
                }
            });
    };
}

function getUserComplete(data) {
    return {
        type: types.USER_GET_COMPLETE,
        getUser: data
    };
}

export function resetPermission() {
    return async (dispatch) => {
        dispatch(getPermissionComplete(null));
    };
}

export function getPermission(userID, screenID, cb) {
    let strScreenID = [];
    if (Array.isArray(screenID)) {
        strScreenID = screenID.slice(0);
    } else {
        strScreenID.push(screenID);
    }
    const database = Config.CompanyID();
    const param = {
        "CompanyID": database,
        "strScreenID": JSON.stringify(strScreenID),
    };
    return async (dispatch) => {
        dispatch(getPermissionComplete(-1));
        await Api.put('/W00V0001/permission-action', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (result.code !== "SYS000") {
                        dispatch(getPermissionComplete(0));
                        cb && cb(0);
                    }
                } else if (result.data && result.data.length > 0) {
                    if (screenID && Array.isArray(screenID)) {
                        dispatch(getPermissionComplete(1));
                        cb && cb(result.data);
                        // dispatch(getPermissionComplete(0));
                        // cb && cb([]);
                    }
                    else if (result.data[0] && result.data[0].Permission) {
                        dispatch(getPermissionComplete(result.data[0].Permission));
                        cb && cb(result.data[0].Permission);
                        // dispatch(getPermissionComplete(0));
                        // cb && cb(0);
                    }
                    else {
                        dispatch(getPermissionComplete(0));
                        cb && cb(0);
                    }
                } else {
                    dispatch(getPermissionComplete(0));
                    cb && cb(0);
                }
            });
    };
}

function getPermissionComplete(data) {
    return {
        type: types.USER_GET_PERMISSION_COMPLETE,
        isPermission: data
    };
}

export function list(param, cb) {
    return (dispatch) => {
        // console.log("============== listCompany -> param: ", param);

        Api.put('/user/search', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result);
                    dispatch(listComplete(result));
                }
            });
    };
}

function listComplete(data) {
    return {
        type: types.USER_LIST_COMPLETE,
        listUser: data
    };
}

export function add(param, cb) {
    // console.log("============== addUser -> param: ", param);

    return (dispatch) => {

        Api.put('/user/add', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(addComplete(result.data));
                }
            });
    }
}

function addComplete(data) {
    return{
        type: types.USER_ADD_COMPLETE,
        addUser: data
    }
}

export function edit(param, cb) {
    // console.log("============== editUser-> param: ", param);

    return(dispatch) => {
        Api.put('/user/edit', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(editComplete(result.data));
                }
            });
    }
}

function editComplete(data) {
    return{
        type: types.USER_EDIT_COMPLETE,
        editUser: data
    }
}

export function del(param, cb) {
    // console.log("============== del-> param: ", param);

    return(dispatch) => {
        Api.put('/user/del', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result);
                    dispatch(removeComplete(result));
                }
            });
    }
}

function removeComplete(data) {
    return{
        type: types.USER_DELETE_COMPLETE,
        delUser: data
    }
}

export function delMore(param, cb) {
    // console.log("============== delMore-> param: ", param);

    return(dispatch) => {
        Api.put('/user/del', param)
            .then(result => {
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result);
                    dispatch(delMoreComplete(result));
                }
            });
    }
}

function delMoreComplete(data) {
    return{
        type: types.USER_DELETE_ALL_COMPLETE,
        delUser: data
    }
}