/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/05/27 15:54
 * @update 2019/05/27 15:54
 */

import * as types from '../../types.js';
import Api from '../../../services/api.js';


export function getStatusColumn(param,cb){
        return ()=>{
            Api.put('/W19F1021/get-status-column',param)
                .then(result => {
                    if (result && result.code && result.code !== 200) {
                        if (cb) cb(result, null);
                    } else {
                        if (cb) cb(null, result.data);
                    }
                });
        }
}

export function dataGridW19F1020(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1020/load-store-W17P1622', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dataGridW19F1020Complete(result.data));
                }
            });
    };
}

function dataGridW19F1020Complete(data) {
    return {
        type: types.W19_W19F1020_DATAGRID_COMPLETE,
        dataGrid: data
    };
}

export function loadDataW19F1020(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1020/load-store-W17P1622', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDataW19F1020Complete(result.data));
                }
            });
    };
}

function loadDataW19F1020Complete(data) {
    return {
        type: types.W19_W19F1020_LOAD_DATA_COMPLETE,
        loadDataW19F1020: data
    };
}

export function cbTypeTimeW19F1020(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1020/combo-type-time', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cbTypeTimeW19F1020Complete(result.data));
                }
            });
    };
}

function cbTypeTimeW19F1020Complete(data) {
    return {
        type: types.W19_W19F1020_CB_TYPE_TIME_COMPLETE,
        cbTypeTimeW19F1020: data
    };
}

export function masterW19F1021(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1021/load-store-W17P1604', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(masterW19F1021Complete(result.data));
                }
            });
    };
}

function masterW19F1021Complete(data) {
    return {
        type: types.W19_W19F1021_MASTER_COMPLETE,
        masterW19: data
    };
}

export function dataGirdW19F1021(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1021/load-store-W17P1612', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dataGirdW19F1021Complete(result.data));
                }
            });
    };
}

function dataGirdW19F1021Complete(data) {
    return {
        type: types.W19_W19F1021_DATAGRID_COMPLETE,
        dataGridW19: data
    };
}

export function loadBaseCurrency(param, cb) {
    //console.log('======loadPageNumbeW18F1022=======',param);
    return (dispatch) => {
        Api.put('/mssql/query', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadBaseCurrencyComplete(result));
                }
            });
    };
}

function loadBaseCurrencyComplete(data) {
    return {
        type: types.W19_W19F1021_LOAD_BASE_CURRENCY_COMPLETE,
        loadBaseCurrency: data
    };
}


export function loadListStatus(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1021/load-list-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadListStatusComplete(result.data));
                }
            });
    };
}

function loadListStatusComplete(data) {
    return {
        type: types.W19_W19F1021_LOAD_LIST_STATUS_COMPLETE,
        statusList: data
    };
}
