/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadW18F1002(param, cb) {
    return (dispatch) => {
        Api.put('/w18/w18f1002', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadW18F1002Complete(result.data));
                }
            });
    };
}

function loadW18F1002Complete(data) {
    return {
        type: types.W18_W18F1002_COMPLETE,
        loadW18F1002: data
    };
}

export function loadMasterW18F1012(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1012/load-store-w17p1010', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadMasterW18F1012Complete(result.data));
                }
            });
    };
}

function loadMasterW18F1012Complete(data) {
    return {
        type: types.W18_W18F1012_COMPLETE,
        dataMasterW18F1012: data
    };
}

export function loadGridW18F1012(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1012/load-store-w17p1011', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridW18F1012Complete(result.data));
                }
            });
    };
}

function loadGridW18F1012Complete(data) {
    return {
        type: types.W18_W18F1012_GRID_COMPLETE,
        gridDataW18F1012: data
    };
}

export function loadActionTabW18F1012(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1012/load-store-w17p1018', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadActionTabW18F1012Complete(result.data));
                }
            });
    };
}

export function loadActionTabW18F1012Complete(data) {
    return {
        type: types.W18_W18F1012_ACTION_TAB_COMPLETE,
        actionTabDataW18F1012: data
    };
}

export function cboCompanyStatusW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-company-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCompanyStatusW18F1011Complete(result.data));
                }
            });
    };
}

function cboCompanyStatusW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOCOMPANYSTATUS_COMPLETE,
        cboCompanyStatusW18F1011: data
    };
}

export function cboCaseSourceW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-case-source', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCaseSourceW18F1011Complete(result.data));
                }
            });
    };
}

function cboCaseSourceW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOCASESOURCE_COMPLETE,
        cboCaseSourceW18F1011: data
    };
}

export function cboCompanyGroupW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-company-group', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCompanyGroupW18F1011Complete(result.data));
                }
            });
    };
}

function cboCompanyGroupW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOCOMPANYGROUP_COMPLETE,
        cboCompanyGroupW18F1011: data
    };
}

export function cboGroupSalesW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-group-sales', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboGroupSalesW18F1011Complete(result.data));
                }
            });
    };
}

function cboGroupSalesW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOGROUPSALES_COMPLETE,
        cboGroupSalesW18F1011: data
    };
}

export function cboSalesPersonW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-sales-person', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboSalesPersonW18F1011Complete(result.data));
                }
            });
    };
}

function cboSalesPersonW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOSALESPERSON_COMPLETE,
        cboSalesPersonW18F1011: data
    };
}

export function cboIndutryGroupW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-industry-group', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboIndutryGroupW18F1011Complete(result.data));
                }
            });
    };
}

function cboIndutryGroupW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOINDUTRYGROUP_COMPLETE,
        cboIndutryGroupW18F1011: data
    };
}

export function cboProvinceW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-province', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboProvinceW18F1011Complete(result.data));
                }
            });
    };
}

function cboProvinceW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOPROVINCE_COMPLETE,
        cboProvinceW18F1011: data
    };
}

export function cboCustomerLevelW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-customer-level', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCustomerLevelW18F1011Complete(result.data));
                }
            });
    };
}

function cboCustomerLevelW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOCUSTOMERLEVEL_COMPLETE,
        cboCustomerLevelW18F1011: data
    };
}

export function cboCompetitorW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-competitor', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCompetitorW18F1011Complete(result.data));
                }
            });
    };
}

function cboCompetitorW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOCOMPETITOR_COMPLETE,
        cboCompetitorW18F1011: data
    };
}

export function cboPartnerW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-partner', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboPartnerW18F1011Complete(result.data));
                }
            });
    };
}

function cboPartnerW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOPARTNER_COMPLETE,
        cboPartnerW18F1011: data
    };
}

export function cboNormW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-norm', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboNormW18F1011Complete(result.data));
                }
            });
    };
}

function cboNormW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBONORM_COMPLETE,
        cboNormW18F1011: data
    };
}

export function cboCompanySizeW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-company-size', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCompanySizeW18F1011Complete(result.data));
                }
            });
    };
}

function cboCompanySizeW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOCOMPANYSIZE_COMPLETE,
        cboCompanySizeW18F1011: data
    };
}

export function cboRevenueW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-revenue', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboRevenueW18F1011Complete(result.data));
                }
            });
    };
}

function cboRevenueW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOREVENUE_COMPLETE,
        cboRevenueW18F1011: data
    };
}

export function cboDistrictW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-district', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboDistrictW18F1011Complete(result.data));
                }
            });
    };
}

function cboDistrictW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBODISTRICT_COMPLETE,
        cboDistrictW18F1011: data
    };
}

export function cboWardW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-ward', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboWardW18F1011Complete(result.data));
                }
            });
    };
}

function cboWardW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBOWARD_COMPLETE,
        cboWardW18F1011: data
    };
}

export function cboDataW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-cbo-norm-by-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboDataW18F1011Complete(result.data));
                }
            });
    };
}

function cboDataW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_CBODATA_COMPLETE,
        cboDataW18F1011: data
    };
}

export function getCompanyNameW18F1011(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1011/get-company-name', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCompanyNameW18F1011Complete(result.data));
                }
            });
    };
}

function getCompanyNameW18F1011Complete(data) {
    return {
        type: types.W18_W18F1011_COMPANYNAME_COMPLETE,
        getCompanyNameW18F1011: data
    };
}


export function addNewCompanyW18F1010(param, cb) {
    //Man hình đang dung chung: W18F1011
    return () => {
        Api.put('/w18f1010/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);


                } else {
                    if (cb) cb(null, result);
                }
            });
    }
}

export function editCompanyW18F1010(param, cb) {
    //Man hình đang dung chung: W18F1011
    return () => {
        Api.put('/w18f1010/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);


                } else {
                    if (cb) cb(null, result);
                }
            });
    }
}


export function loadW18F1013(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1012/load-store-w17p1017', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadW18F1013Complete(result.data));
                }
            });
    };
}

export function loadW18F1013Complete(data) {
    return {
        type: types.W18_W18F1013_COMPLETE,
        loadW18F1013: data
    };
}

export function addW18F1013(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1020/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);


                } else {
                    if (cb) cb(null, result.data);
                    dispatch(addW18F1013Complete(result.data));
                }
            });
    };
}

function addW18F1013Complete(data) {
    return {
        type: types.W18F1013_ADD_COMPLETE,
        addW18F1013: data
    }
}

export function editW18F1013(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1020/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(editW18F1013Complete(result.data));
                }
            });
    };
}

function editW18F1013Complete(data) {
    return {
        type: types.W18F1013_EDIT_COMPLETE,
        editW18F1013: data
    }
}

export function deleteW18F1013(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1020/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(deleteW18F1013Complete(result.data));
                }
            });
    };
}

function deleteW18F1013Complete(data) {
    return {
        type: types.W18F1013_DELETE_COMPLETE,
        deleteW18F1013: data
    }
}


export function loadW18F1000(param, cb) {
    return (dispatch) => {
        Api.put('/w18/w18f1000', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadW18F1000Complete(result.data));
                }
            });
    };
}

function loadW18F1000Complete(data) {
    return {
        type: types.W18_W18F1000_COMPLETE,
        loadW18F1000: data
    };
}

export function deleteW18F1000(param, cb) {
    return (dispatch) => {
        Api.put('/W18F1000/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW18F1000Complete(result));
                }
            });
    };
}

function deleteW18F1000Complete(data) {
    return {
        type: types.W18_W18F1000_DELETE_COMPLETE,
        deleteW18F1000: data
    };
}


export function addW18F1001(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1001/add-customer-group', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(addW18F1001Complete(result));
                }
            });
    };
}

function addW18F1001Complete(data) {
    return {
        type: types.W18_W18F1001_ADD_COMPLETE,
        addW18F1001: data
    };
}

export function editW18F1001(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1001/save-customer-group', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(editW18F1001Complete(result));
                }
            });
    };
}

function editW18F1001Complete(data) {
    return {
        type: types.W18_W18F1001_EDIT_COMPLETE,
        editW18F1001: data
    };
}

export function loadW18F1030(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1030/load-store-W17P1031', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);

                    dispatch(loadW18F1030Complete(result.data));
                }
            });
    };
}

function loadW18F1030Complete(data) {
    return {
        type: types.W18_W18F1030_COMPLETE,
        loadW18F1030: data
    };
}

export function deleteW18F1031(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1030/del-by-W17P1034', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW18F1031Complete(result));
                }
            });
    };
}

function deleteW18F1031Complete(data) {
    return {
        type: types.W18_W18F1031_DELETE_COMPLETE,
        deleteW18F1031: data
    };
}

export function checkStoreW18F1030(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1030/del-by-sales-person-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(checkStoreW18F1030Complete(result));
                }
            });
    };
}

function checkStoreW18F1030Complete(data) {
    return {
        type: types.CHECKING_STOREW18F1030_COMPLETE,
        checkStoreW18F1030: data
    };
}

export function loadQuantityW18F1030(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1030/quantity-employee', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadQuantityW18F1030Complete(result));
                }
            });
    };
}

function loadQuantityW18F1030Complete(data) {
    return {
        type: types.W18_W18F1030_QUANTITY_COMPLETE,
        loadQuantityW18F1030: data
    };
}

export function checkExistMobile(param, cb) {
    return (dispatch) => {
        Api.put('/w18/w18f1013-check-exist-mobile-no', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function btnConvertAction(param, cb) {
    return (dispatch) => {
        Api.put('/W18F1012/conversion-request', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function updateCustomer(param, cb) {
    return () => {
        Api.put('/w18f1011/update-customer', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);


                } else {
                    if (cb) cb(null, result);
                }
            });
    }
}

export function loadCheckRequired(param, cb) {
    return () => {
        Api.put('/w18f1011/check-required', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result?.data);
                }
            });
    };
}


