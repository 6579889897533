/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/25/03 10:35
 * @update 2019/25/03 10:35
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function loadW17F0010(param, cb) {
    return (dispatch) => {
        Api.put('/W17F0010/load-data-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadW17F0010Complete(result));
                }
            });
    };
}

function loadW17F0010Complete(result) {
    return {
        type: types.W17_W17F0010_LOAD_COMPLETE,
        loadW17F0010: result
    };
}

export function editW17F0010(param, cb) {
    return (dispatch) => {
        Api.put('/D17T0013/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(editW17F0010Complete(result));
                }
            });
    };
}

function editW17F0010Complete(result) {
    return {
        type: types.W17_W17F0010_EDIT_COMPLETE,
        editW17F0010: result
    };
}

export function loadFieldRequiredW17F0010(param, cb) {
    return (dispatch) => {
        Api.put('/W17F0010/combo-code-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadFieldRequiredW17F0010Complete(result));
                }
            });
    };
}

function loadFieldRequiredW17F0010Complete(data) {
    return {
        type: types.W17_W17F0010_LOAD_FIELDEQUIRED_COMPLETE,
        loadFieldRequiredW17F0010: data
    };
}
export function saveCustomerSetting(param,cb) {
    return () => {
        Api.put('/D17T0009/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
export function loadCustomerSetting(param, mode,cb) {
    return (dispatch) => {
        Api.put('/D17T0009/get', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCustomerSettingComplete(result,mode));
                }
            });
    };
}

function loadCustomerSettingComplete(data,mode) {
    switch(mode){
        case 'numDateChange':
            return {
                type: types.W17_W17F0010_NUMDATECHANGE_COMPLETE,
                 numDateChange: data
            };
        case 'condition':
            return {
                type: types.W17_W17F0010_CONDITION_COMPLETE,
                condition: data
            };
        default:
            return null;
    }
}

export function dataCondition(param,cb) {
    return (dispatch) => {
        Api.put('/W17V0009/search', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data.rows);
                    dispatch(dataConditionComplete(result.data.rows));
                }
            });
    };
}

function dataConditionComplete(data) {
            return {
                type: types.W17_W17F0010_DATACONDITION_COMPLETE,
                dataCondition: data
            };

}
// export function editW17F0010(param, cb) {
//     return () => {
//         Api.put('/D17T0013/edit', param)
//             .then(result => {
//                 if (result && result.code && result.code !== 200) {
//                     if (cb) cb(result, null);
//                 } else {
//                     if (cb) cb(null, result);
//                 }
//             });
//     };
// }