/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 11/23/2020
 * @Example 
 */

import Config from "../config/index";
import Api from '../services/api.js';

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data 
}

export default async (param, cb) => {
    return new Promise((resolve, reject) => {
        Api.putExport('/export/export-flex-report', param)
            .then(async response => {
                if (response && response.ok) {
                    let _res = null;
                    if (response.type === "json") {
                        _res = await response.data.json();
                        if (_res && _res.data) {
                            cb && cb(null, _res.data);
                        } else {
                            cb && cb(_res, null)
                        }
                    } else {
                        const content = await response.data.blob();
                        const datetime = new Date().getTime();
                        const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-crm-" + datetime + '.xls';

                        _res = {
                            fileName: _fileName,
                            fileExt: Config.helpers.getExtFile(_fileName),
                            URL: URL.createObjectURL(content),
                        };
                        if (_res && _res.URL) {
                            cb && cb(null, _res);
                        } else {
                            cb && cb(_res, null);
                        }
                    }
                } else {
                    if (cb) cb(null, response.data);
                }
            });
    });
};
