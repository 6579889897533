/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/29/03 09:45
 * @update 2019/29/03 09:45
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadW18F1022(param, cb) {
    //console.log('======loadW18F1022=======',param);
    return (dispatch) => {
        Api.put('/w18f1022/load-store-W17P1022', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW18F1022Complete(result));
                }
            });
    };
}

function loadW18F1022Complete(data) {
    return {
        type: types.W18_W18F1022_LOAD_COMPLETE,
        loadW18F1022: data
    };
}

export function loadCbTranType(param, cb) {
    //console.log('======loadPageNumbeW18F1022=======',param);
    return (dispatch) => {
        Api.put('/w18f1022/show-trans-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCbTranTypeComplete(result.data));
                }
            });
    };
}

function loadCbTranTypeComplete(data) {
    return {
        type: types.W18_W18F1022_LOAD_TRANSTYPE_COMPLETE,
        loadCbTranType: data
    };
}
