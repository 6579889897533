/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/08/08 08:21
 * @update 2019/08/08 08:21
 */

import Config from '../../config';
import LocalizedStrings from 'react-localization';
import {localize}          from "../../localize/localize";

export default async ()=> {
    console.log('====== loading-localize =======');

    return new Promise(async (resolve, reject) => {
        let cfLocalize = null;
        let lang = Config.getLocalStorage("langCRM");

        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langCRM','vi');
        }

        try {
            const lc = Config.getLocalStorage('LOCALIZE');
            cfLocalize = lc ? JSON.parse(lc) : null;

            if (!cfLocalize || cfLocalize.timestamps !== localize.timestamps) {
                cfLocalize = localize;
                Config.setLocalStorage('LOCALIZE',JSON.stringify(cfLocalize));
            }

        } catch (e) {
            reject({code: "ERL001", message: JSON.stringify(e), data: null});
        }

        Config.localization = new LocalizedStrings(cfLocalize);
        Config.localization.setLanguage(lang);

        if (lang === "vi"){
            Config.language = "84";
        } else {
            Config.language = "01";
        }

        if (Config.localization) {
            resolve({code: 200, message: "", data: Config.localization});
        } else {
            reject({code: "ERL001", message: "Does not init localize", data: null});
        }
    } );
};