import Api from "../../../services/api";
import * as types from "../../types";

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

export function loadMaster(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1018/load-store-by-mode', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadMasterComplete(result.data));
                }
            });
    };
}

function loadMasterComplete(data) {
    return {
        type: types.W18_W18F1018_DATAMASTER_COMPLETE,
        dataMaster: data
    };
}

export function loadGridW18F1018(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1018/load-store-by-form-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridComplete(result.data));
                }
            });
    };
}

function loadGridComplete(data) {
    return {
        type: types.W18_W18F1018_POPUP_COMPLETE,
        dataPopUp: data
    };
}

export function deleteProduct(param,cb) {
    return () => {
        Api.put('/w18f1017/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}
