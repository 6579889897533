/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from '../../types';
import Api from '../../../services/api.js';
export function dataGrid(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1016/load-store-w17p1020', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDataGridComplete(result.data));
                }
            });
    };
}


function loadDataGridComplete(data) {
            return {
                type: types.W18_W18F1016_LOADGRID_COMPLETE,
                dataGrid: data
            };

}

