/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 11/23/2020
 * @Example 
 */

import Api from '../services/api.js';
export default async (param, cb) => {
    return new Promise((resolve, reject) => {
        Api.put('/export/passing-flex-report-parameter', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    });
};
