/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/23/2021
 * @Example
 */

import Api from '../../../services/api.js';
import * as types from '../../../../src/actions/types'

export function loadCboInventoryType(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/get-cbo-inventory-type', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCboInventoryTypeComplete(result.data));
                }
            });
    };
}

function loadCboInventoryTypeComplete(data) {
    return {
        type: types.W17F5560_GET_CBO_INVENTORY_TYPE_COMPLETE,
        data: data
    }
}

export function loadCboInventory(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/get-cbo-inventory', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCboInventoryComplete(result.data));
                }
            });
    };
}

function loadCboInventoryComplete(data) {
    return {
        type: types.W17F5560_GET_CBO_INVENTORY_COMPLETE,
        data: data
    }
}

export function loadCboTypeCode(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/get-cbo-typecode', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCboTypeCodeComplete(result.data));
                }
            });
    };
}

function loadCboTypeCodeComplete(data) {
    return {
        type: types.W17F5560_GET_CBO_TYPECODE_COMPLETE,
        data: data
    }
}

export function loadCboCode(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/get-cbo-code', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCboCodeComplete(result.data));
                }
            });
    };
}

function loadCboCodeComplete(data) {
    return {
        type: types.W17F5560_GET_CBO_CODE_COMPLETE,
        data: data
    }
}

export function loadColumns(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/load-columns', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadColumnsComplete(result.data));
                }
            });
    };
}

function loadColumnsComplete(data) {
    return {
        type: types.W17F5560_GET_COLUMNS_COMPLETE,
        data: data
    }
}

export function loadForm(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/load-form', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadFormComplete(result.data));
                }
            });
    };
}

function loadFormComplete(data) {
    return {
        type: types.W17F5560_GET_FORM_COMPLETE,
        data: data
    }
}

export function loadCboWareHouse(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/get-cbo-warehouse', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCboWareHouseComplete(result.data));
                }
            });
    };
}

function loadCboWareHouseComplete(data) {
    return {
        type: types.W17F5560_GET_CBO_WAREHOUSE_COMPLETE,
        data: data
    }
}

export function getQuantityDecimal(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/get-quantity-decimals', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadQuantityDecimalComplete(result.data));
                }
            });
    };
}

function loadQuantityDecimalComplete(data) {
    return {
        type: types.W17F5560_GET_QUANTITY_DECIMAL_COMPLETE,
        data: data
    }
}
