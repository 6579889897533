/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function dataW18F4000(param, cb) {
    return (dispatch) => {
        Api.put('/w18/w18f1010', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(dataW18F4000Complete(result.data));
                }
            });
    };
}

function dataW18F4000Complete(data) {
    return {
        type: types.W18F4000_LOAD_REPORT_COMPLETE,
        dataW18F4000: data
    };
}

export function exportExcel(param, cb) {
    return (dispatch) => {
        Api.put('/w18/w18f1010', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function cboTaskType(param, cb) {
    return (dispatch) => {
        Api.get('/w18f4000/data-by-item-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboTaskTypeComplete(result.data));
                }
            });
    };
}

function cboTaskTypeComplete(data) {
    return {
        type: types.W18F4000_LOAD_TASK_TYPE_COMPLETE,
        cboTaskType: data
    };
}

export function cboStatus(param, cb) {
    return (dispatch) => {
        Api.put('/w18f4000/data-by-list-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboStatusComplete(result.data));
                }
            });
    };
}

function cboStatusComplete(data) {
    return {
        type: types.W18F4000_LOAD_STATUS_COMPLETE,
        cboStatus: data
    };
}

// export function apiQuery(param, mode, cb) {
//     //API  dung  de query  sql
//     return (dispatch) => {
//         Api.put('/mssql/query', param)
//             .then(result => {
//                 if (result && result.code && result.code !== 200) {
//                     if (cb) cb(result, null);
//                 } else {
//                     if (cb) cb(null, result.data);
//                     dispatch(apiQueryComplete(result.data, mode));
//                 }
//             });
//     };
// }
//
// function apiQueryComplete(data, mode) {
//     switch (mode) {
//         // case 'TaskType':
//         //     return {
//         //         type: types.W18F4000_LOAD_TASK_TYPE_COMPLETE,
//         //         cboTaskType: data
//         //     };
//         case 'Status':
//             return {
//                 type: types.W18F4000_LOAD_STATUS_COMPLETE,
//                 cboStatus: data
//             };
//         default:
//             return null;
//
//     }
//
// }