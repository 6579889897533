import Api from "../../../services/api";
import * as types from "../../types";

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 15:37
 * @update 2019/03/28 15:37
 */

export function loadGrid(param, cb) {
    return (dispatch) => {
        Api.put('/W19F2001/get-data-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridComplete(result.data));
                }
            });
    };
}


function loadGridComplete(data) {
            return {
                type: types.W19_W19F2000_DATAGRID_COMPLETE,
                dataGrid: data
            };


}

export function deleteGrid(param, cb) {
    return () => {
        Api.delete('/w19f2000/delete', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
export function checkEdit(param, cb) {
    return () => {
        Api.put('/w19f2000/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Cbo Inventory and decimal
export function loadCboInventoryAndDecimal(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1000/get-list', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCboInventoryAndDecimalComplete(result.data));
                }
            });
    };
}


function loadCboInventoryAndDecimalComplete(data) {
    return {
        type: Object.keys(data[0]).includes("OriginalDecimal")
            ? types.W19_W19F2000_DECIMAL_COMPLETE
            : types.W19_W19F2000_CBO_INVENTORY_COMPLETE,
        data,
    };
}
