/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/04/16 10:30
 * @update 2019/04/16 10:30
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadW19F1010(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1010/load-store-W17P2020', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW19F1010Complete(result));
                }
            });
    };
}

function loadW19F1010Complete(data) {
    return {
        type: types.W19_W19F1010_LOAD_COMPLETE,
        loadW19F1010: data
    };
}

export function comboGroupSalesW19F1010(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1010/load-store-W17P9000', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(comboGroupSalesW19F1010Complete(result));
                }
            });
    };
}

function comboGroupSalesW19F1010Complete(data) {
    return {
        type: types.W19_W19F1010_CBGROUPSALE_COMPLETE,
        comboGroupSalesW19F1010: data
    };
}

export function comboYearW19F1010(param, cb) {
    return (dispatch) => {
        Api.put('/D19F1010/search', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(comboYearW19F1010Complete(result));
                }
            });
    };
}

function comboYearW19F1010Complete(data) {
    return {
        type: types.W19_W19F1010_CBYEAR_COMPLETE,
        comboYearW19F1010: data
    };
}

export function saveW19F1010(param, cb) {
    return (dispatch) => {
        Api.put('/D19F1010/save', param)
            .then(result => {
                if (result && result.code && result.message) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(saveW19F1010Complete(result));
                }
            });
    };
}

function saveW19F1010Complete(data) {
    return {
        type: types.W19_W19F1010_SAVE_COMPLETE,
        saveW19F1010: data
    };
}


