import Api from "../../../services/api";
import * as types from "../../types";

/**
 * @copyright 2019 @ DigiNet
 * @author Rocachien
 * @create 2020/04/24 10:37
 * @update 2020/04/24 10:37
 */

export function apiStore(param, cb) {
    return () => {
        Api.put('/W19F2001/load-store-W17P2051', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}


export function getBasePrice(param, cb) {
    return () => {
        Api.put('/W19F2001/get-base-price', param)
        // Api.put('/W19F2001/add-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data[0]);
                }
            });
    };
}

export function savePopUp(param, cb) {
    return () => {
        Api.put('/W19F1001/approve', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}


export function loadshipAddress(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1001/cboShipAddress', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadshipAddressComplete(result.data));
                }
            });
    };
}


function loadshipAddressComplete(data) {
    return {
        type: types.W19_W19F2001_CBOSHIPADDRESS_COMPLETE,
        cboShipAddress: data
    };


}

export function loadDataShare(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1001/getShare', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDataShareComplete(result.data));
                }
            });
    };
}


function loadDataShareComplete(data) {
    return {
        type: types.W19_W19F2001_CBOSHARE_COMPLETE,
        dataShare: data
    };


}

export function loadDataStatus(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1001/cboStatusID', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDataStatusComplete(result.data));
                }
            });
    };
}


function loadDataStatusComplete(data) {
    return {
        type: types.W19_W19F2001_CBOSTATUS_COMPLETE,
        cboStatus: data
    };


}

export function loadDataMaster(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1001/getMaster', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDataMasterComplete(result.data));
                }
            });
    };
}


function loadDataMasterComplete(data) {
    return {
        type: types.W19_W19F2001_DATAMASTER_COMPLETE,
        dataMaster: data
    };


}

export function loadPerApprove(param, cb) {
    return () => {
        Api.put('/W19F1001/getPerApprove', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}


export function loadDataGrid(param, cb) {
    return (dispatch) => {
        Api.put('/W19F2001/get-detail', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadDataGridComplete(result.data));
                }
            });
    };
}

export function updateStatus(param, cb) {
    return () => {
        Api.put('/W19F1001/update-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function getDefaultCurrency(cb) {
    return () => {
        Api.get('/W19F1021/cbo-base-cur-id')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

function loadDataGridComplete(data) {
    return {
        type: types.W19_W19F2001_DATAGRID_COMPLETE,
        dataGrid: data
    };
}

export function getNumValue(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1000/get-num-value', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getNumValueComplete(result.data));
                }
            });
    };
}

function getNumValueComplete(data) {
    return {
        type: types.W19_W19F2001_NUMVALUE_COMPLETE,
        numValue: data
    };


}


export function getBaseCurrency(cb) {
    return () => {
        Api.put('/W19F1001/getCurrency')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// export function loadUnit(param, cb) {
//     return () => {
//         Api.put('/W19F1001/cboUnitID', param)
//             .then(result => {
//                 if (result && result.code && result.code !== 200) {
//                     if (cb) cb(result, null);
//                 } else {
//                     if (cb) cb(null, result.data);
//                 }
//             });
//     };
// }

export function loadCaption(cb) {
    return () => {
        Api.put('/W19F1001/loadCaption')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadToggleColumn(cb) {
    return async () => {
        await Api.put('/W19F2001/load-column')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };

}

export function loadContact(param, cb) {
    return () => {
        Api.put('/W19F1001/cboContact', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function addNew(param, cb) {
    return () => {
        Api.put('/W19F2001/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function edit(param, cb) {
    return () => {
        Api.put('/W19F2001/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function getExtraInfo(cb) {
    return (dispatch) => {
        Api.put('/W19F1001/get-extra-info')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(getExtraInfoComplete(result.data));
                }
            });
    };
}

function getExtraInfoComplete(data) {
    return {
        type: types.W19_W19F2001_EXTRAINFO_COMPLETE,
        extraInfo: data
    };


}

export function checkDRef5U(cb) {
    return () => {
        Api.put('/W19F1001/check-dropdown-or-text')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function getCboDref5(cb) {
    return () => {
        Api.put('/W19F1001/get-source-dref5u')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}

export function getDefaultVRef5(param, cb) {
    return async () => {
        await Api.put('/W19F1001/get-default', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                    return true
                } else {
                    if (cb) cb(null, result);
                    return true
                }
            });
    }

}

export function closeQuotation(param, cb) {
    return async () => {
        Api.put('/W19F1001/close-quotation', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    }
}

export function getFormatDecimal(param, cb) {
    Api.put('/W19F1001/get-format-decimal', param)
        .then(result => {
            if (result && result.code && result.code !== 200) {
                if (cb) cb(result, null);
            } else {
                if (cb) cb(null, result);
            }
        });
}
