/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/06/03 16:46
 * @file src/actions/dash-board-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function getListParts(param, cb) {
    return (dispatch) => {
        // console.log("============== getUser -> param: ", param);

        Api.put('/W17F9000/get-list-dashboard', param)
            .then(result => {
                // console.log('result========', param);
                // console.log(result);
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(getAPIComplete(result.data,'List'));
                }
            });
    };
}

export function getReportColumn(param, cb) {
    return (dispatch) => {
        // console.log("============== getUser -> param: ", param);

        Api.put('/W17F9000/get-list-report', param)
            .then(result => {
                // console.log('result========', param);
                // console.log(result);
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(getAPIComplete(result.data,'ListReport'));
                }
            });
    };
}

export function getReportMatrix(param, cb) {
    return (dispatch) => {
        // console.log("============== getUser -> param: ", param);

        Api.put('/W19F4001/data-report-grid', param)
            .then(result => {
                // console.log('result========', param);
                // console.log(result);
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(getAPIComplete(result.data,'GridReport'));
                }
            });
    };
}

export function getConpoTypeTime(param, cb) {
    return (dispatch) => {
        // console.log("============== getUser -> param: ", param);

        Api.put('/W19F4000/get-report-period', param)
            .then(result => {
                // console.log('result========', param);
                // console.log(result);
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(getAPIComplete(result.data,'TypeTime'));
                }
            });
    };
}

export function getConpoTypeTime2(param, cb) {
    return (dispatch) => {
        // console.log("============== getUser -> param: ", param);

        Api.get('/W19F4001/combo-report-period', param)
            .then(result => {
                // console.log('result========', param);
                // console.log(result);
                if(result && result.code && result.message) {
                    if(cb) cb(result, null);
                } else {
                    if(cb) cb(null, result.data);
                    dispatch(getAPIComplete(result.data,'TypeTime2'));
                }
            });
    };
}

function getAPIComplete(data, mode) {
    switch (mode) {
        case 'List':
            return{
                type: types.GET_LIST_PART_COMPLETE,
                listDashboard: data
            };
        case 'ListReport':
            return{
                type: types.GET_LIST_REPORT_COMPLETE,
                listReportColumn: data
            };
        case 'TypeTime':
            return{
                type: types.GET_TYPE_TIME_COMPLETE,
                typeTime: data
            };
        case 'GridReport':
            return{
                type: types.GET_GRID_REPORT_COMPLETE,
                gridReportColumn: data
            };
        case 'TypeTime2':
            return{
                type: types.GET_TYPE_TIME2_COMPLETE,
                typeTime2: data
            };
        default: break;
    }
}