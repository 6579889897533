/**
 * @copyright 2020 @ DigiNet
 * @author rocachien
 * @create 2020/04/28 15:37
 * @update 2020/04/28 15:37
 */

import Api from '../../../services/api.js';
export default async (param)=> {
    return new Promise((resolve, reject) => {
        Api.put('/W19F2001/get-base-price', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    return resolve(0);
                } else {
                    return resolve(result.data);
                }
            });
    });
};
