/**
 * @copyright 2019 @ DigiNet
 * @author Tram
 * @create 2019/03/14 14:00
 * @update 2019/03/14 14:00
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function loadW17F0003(param, cb) {
    return (dispatch) => {
        Api.put('/w17f0003/history-change-target', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW17F0003Complete(result.data));
                }
            });
    };
}

function loadW17F0003Complete(data) {
    return {
        type: types.W17_W17F0003_LOAD_COMPLETE,
        loadW17F0003: data
    };
}

// export function loadComboW17F0003(param, cb) {
//     return (dispatch) => {
//         Api.put('/w17f0003/cbo-users', param)
//             .then(result => {
//                 if (result && result.code && result.code !== 200) {
//                     if (cb) cb(result, null);
//                 } else {
//                     if (cb) cb(null, result);
//                     dispatch(loadComboW17F0003Complete(result.data));
//                 }
//             });
//     };
// }
//
// function loadComboW17F0003Complete(data) {
//     return {
//         type: types.W17_W17F0003_LOADCOMBO_COMPLETE,
//         loadComboW17F0003: data
//     };
// }
