/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

import * as types from './types.js';
import Api from '../services/api.js';


export function handOver(param, cb) {
    //API  dung  de query  sql
    return (dispatch) => {
        Api.put('/W18F1025/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(apiQueryComplete(result.data, 'HandOver'));
                }
            });
    };
}





function apiQueryComplete(data, mode) {
    switch (mode) {
        case 'HandOver':
            return {
                type: types.W18_W18F1025_HANDOVER_COMPLETE,
                data: data
            };


        default:
            return null;

    }

}




