import Api from "../../../services/api";
import * as types from "../../types";

/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 20/01/2021
 * @update 29/01/2021
 */

export function loadForm(param, cb) {
    return () => {
        Api.put('/w17f2000/load-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function saveData(param, cb) {
    return () => {
        Api.post('/w17f2000/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//W17F2030
export function loadDataInventory(param, cb) {
    return async () => {
        Api.put('/w17f2030/load-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//Save Data W17F2020
export function saveW17F2020(param, cb) {
    return async () => {
        Api.put('/w17f2020/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//Save Data W17F2030
export function saveW17F2030(param, cb) {
    return async () => {
        Api.put('/w17f2030/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Load Voucher
export function loadVoucher(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-voucher', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Check Edit
export function checkEdit(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Load Currency
export function loadPriceList(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-currency', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Reload Grid after chosen W17F2030
export function reLoadGrid(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Check Status
export function checkStatus(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/check-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Approval
export function approval(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/approve', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Cancel Approval
export function cancelApproval(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/cancel', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//========CÁC COMBO==========
// Get DMF Setting
export function getDmfSetting(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/get-dmf-setting')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getDmfSettingSuccess(result.data));
                }
            });
    };
}

function getDmfSettingSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_DMF_SETTING_SUCCESS,
        dataDMFSetting: data
    };
}

// Combo Division
export function getCboDivision(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-division')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboDivisionSuccess(result.data));
                }
            });
    };
}

function getCboDivisionSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_DIVISION_SUCCESS,
        dataCboDivision: data
    };
}

// Combo TranType
export function getCboTranType(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-tran-type')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboTranTypeSuccess(result.data));
                }
            });
    };
}

function getCboTranTypeSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_TRANTYPE_SUCCESS,
        dataCboTranType: data
    };
}

// Combo Employee
export function getCboEmployee(params, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-combo-employee', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboEmployeeSuccess(result.data));
                }
            });
    };
}

function getCboEmployeeSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_EMPLOYEE_SUCCESS,
        dataCboEmployee: data
    };
}

// Combo Delivery
export function getCboDelivery(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-delivery')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboDeliverySuccess(result.data));
                }
            });
    };
}

function getCboDeliverySuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_DELIVERY_SUCCESS,
        dataCboDeliveryMethod: data
    };
}

// Combo Payment Term
export function getCboPaymentTerm(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-payment-term')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboPaymentTermSuccess(result.data));
                }
            });
    };
}

function getCboPaymentTermSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_PAYMENT_TERM_SUCCESS,
        dataCboPaymentTerm: data
    };
}

// Combo Payment Term
export function getCboPaymentMethod(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-payment-method')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboPaymentMethodSuccess(result.data));
                }
            });
    };
}

function getCboPaymentMethodSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_PAYMENT_METHOD_SUCCESS,
        dataCboPaymentMethod: data
    };
}

// Combo Currency
export function getCboCurrency(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-currency')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboCurrencySuccess(result.data));
                }
            });
    };
}

function getCboCurrencySuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_CURRENCY_SUCCESS,
        dataCboCurrency: data
    };
}

// Combo Status Master
export function getCboStatus(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-status')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboStatusSuccess(result.data));
                }
            });
    };
}

// Combo Status W17F2020
export function getCboStatusW17F2020(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2020/load-cbo-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

function getCboStatusSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_STATUS_SUCCESS,
        dataCboStatus: data
    };
}

// Combo VATGroup
export function getCboVATGroup(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-VATgroup')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboVATGroupSuccess(result.data));
                }
            });
    };
}

function getCboVATGroupSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_VAT_GROUP_SUCCESS,
        dataCboVATGroup: data
    };
}

// Combo Contact Person
export function getCboContactPerson(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-combo-contact-person', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Combo Noi nhan hang
export function getCboShipAddress(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-object-address', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboShipAddressSuccess(result.data));
                }
            });
    };
}

function getCboShipAddressSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_SHIP_ADDRESS_SUCCESS,
        data: data
    };
}

// Combo Quy cách đóng gói
export function getCboPacking(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-cbo-packing', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboPackingSuccess(result.data));
                }
            });
    };
}

function getCboPackingSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_CBO_PACKING_SUCCESS,
        dataCboPacking: data
    };
}

// check task
export function checkTask(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/check-task', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// close
export function actionClose(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/btn-close', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadUnitPrice(param, cb) {
    return () => {
        Api.put('/w17f2000/unit-price', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadCaption(param, cb) {
    return () => {
        Api.put('/w17f2000/load-caption', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function getVirtualItemsValue(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-virtual-items-value')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getVirtualItemsValueSuccess(result.data));
                }
            });
    };
}

function getVirtualItemsValueSuccess(data) {
    return {
        type: types.W17_W17F2000_GET_VIRTUAL_ITEMS_VALUE_SUCCESS,
        data
    };
}
