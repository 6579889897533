/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function exportExcel(param, cb) {
    return (dispatch) => {
        Api.put('/w18/w18f1010', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function cboCustomerStatus(param, cb) {
    return (dispatch) => {
        Api.get('/w18f4001/data-w-item-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCustomerStatusComplete(result.data));
                }
            });
    };
}

function cboCustomerStatusComplete(data) {
    return {
        type: types.W18F4001_LOAD_CUSTOMER_STATUS_COMPLETE,
        cboCustomerStatus: data
    };
}

export function cboCustomerLevel(param, cb) {
    return (dispatch) => {
        Api.get('/w18f4001/data-cus-info', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboCustomerLevelComplete(result.data));
                }
            });
    };
}

function cboCustomerLevelComplete(data) {
    return {
        type: types.W18F4001_LOAD_CUSTOMER_LEVEL_COMPLETE,
        cboCustomerLevel: data
    };
}
export function cboFieldGroup(param, cb) {
    return (dispatch) => {
        Api.get('/w18f4001/data-f-W17V4002', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cboFieldGroupComplete(result.data));
                }
            });
    };
}

function cboFieldGroupComplete(data) {
    return {
        type: types.W18F4001_LOAD_FIELD_GROUP_COMPLETE,
        cboFieldGroup: data
    };
}

// export function apiQuery(param, mode, cb) {
//     //API  dung  de query  sql
//     return (dispatch) => {
//         Api.put('/mssql/query', param)
//             .then(result => {
//                 if (result && result.code && result.code !== 200) {
//                     if (cb) cb(result, null);
//                 } else {
//                     if (cb) cb(null, result.data);
//                     dispatch(apiQueryComplete(result.data, mode));
//                 }
//             });
//     };
// }

// function apiQueryComplete(data, mode) {
//     switch (mode) {
//         // case 'CustomerStatus':
//         //     return {
//         //         type: types.W18F4001_LOAD_CUSTOMER_STATUS_COMPLETE,
//         //         cboCustomerStatus: data
//         //     };
//         // case 'CustomerLevel':
//         //     return {
//         //         type: types.W18F4001_LOAD_CUSTOMER_LEVEL_COMPLETE,
//         //         cboCustomerLevel: data
//         //     };
//         // case 'FieldGroup':
//         //     return {
//         //         type: types.W18F4001_LOAD_FIELD_GROUP_COMPLETE,
//         //         cboFieldGroup: data
//         //     };
//         default:
//             return null;
//
//     }
//
// }

export function getCboGender(param, cb) {
    return (dispatch) => {
        Api.put('/W20F1000/get-cbo-gender', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboGenderComplete(result.data));
                }
            });
    }
}

function getCboGenderComplete(data) {
    return {
        type: types.W18F4001_CBO_GENDER_COMPLETE,
        dataCboGender: data
    };
}