import Api from "../../../services/api";
import * as types from "../../types";

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 15:37
 * @update 2019/03/28 15:37
 */

export function loadGridW18F1019(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1019/load-store-by-mode', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridComplete(result.data));
                }
            });
    };
}

export function saveData(param, cb) {
    return () => {
        Api.put('/D17T1012/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function deletePer(param, cb) {
    return () => {
        Api.put('/D17T1012/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}
function loadGridComplete(data) {
            return {
                type: types.W18_W18F1019_DATAGRID_COMPLETE,
                dataGrid: data
            };


}

export function deleteProduct(param,cb) {
    return () => {
        Api.put('/w18f1017/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                }
            });
    };
}
