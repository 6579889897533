/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/09/04 16:50
 * @update 2019/09/04 16:50
 */

import Api from "../../../services/api";
import * as types from "../../types";


export function loadW17F1510(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1510/grid-category', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(loadW17F1510Complete(result));
                }
            });
    };
}

function loadW17F1510Complete(data) {
    return {
        type: types.W17_W17F1510_DATAGRID_COMPLETE,
        loadW17F1510: data
    };
}

export function addW17F1511(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1511/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(addW17F1511Complete(result.data));
                }
            });
    };
}

function addW17F1511Complete(data) {
    return {
        type: types.W17_W17F1511_ADD_COMPLETE,
        addW17F1511: data
    };
}

export function editW17F1511(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1511/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(editW17F1511Complete(result.data));
                }
            });
    };
}

function editW17F1511Complete(data) {
    return {
        type: types.W17_W17F1511_EDIT_COMPLETE,
        editW17F1511: data
    };
}

export function delW17F1511(param, cb) {
    return (dispatch) => {
        Api.put('/w17f1510/del-evaluate-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(delW17F1511Complete(result.data));
                }
            });
    };
}

function delW17F1511Complete(data) {
    return {
        type: types.W17_W17F1511_EDIT_COMPLETE,
        delW17F1511: data
    };
}

