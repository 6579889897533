import Api from "../services/api";
import * as types from "./types";

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */

export function addNewGroupSales(param, cb) {
    //API su dung store cua sql
    return () => {
        Api.put('/w17f1000/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function editGroupSales(param, cb) {
    //API su dung store cua sql
    return () => {
        Api.put('/w17f1000/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function deleteGroupSales(param, cb) {
    //API su dung store cua sql
    return () => {
        Api.put('/w17f1000/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadOrgListW17F1000(param, cb) {
    return (dispatch) => {
        // console.log("============== listCompany -> param: ", param);
        Api.put('/w17f1000/load-org-list', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadOrgListW17F1000Complete(result.data));
                }
            });
    };
}

function loadOrgListW17F1000Complete(data) {
    return {
        type: types.W17_W17F1000_DATAORGLIST_COMPLETE,
        orgListData: data
    };
}

export function loadTreeListW17F1000(param, cb) {
    return (dispatch) => {
        // console.log("============== listCompany -> param: ", param);
        Api.put('/w17f1000/load-list-W17P9000', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadTreeListW17F1000Complete(result.data));
                }
            });
    };
}

function loadTreeListW17F1000Complete(data) {
    return {
        type: types.W17_W17F1000_DATATREELIST_COMPLETE,
        treeListData: data
    };
}

