import Api from "../../../services/api";
import * as types from "../../types";

/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/08/12 11:00
 * @update 2019/08/12 11:00
 */

export function loadGrid(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1030/grid-evaluation', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridComplete(result.data));
                }
            });
    };
}


function loadGridComplete(data) {
    return {
        type: types.W19_W19F1030_DATAGRID_COMPLETE,
        dataGrid: data
    };
}

export function loadCbSalePerson(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1030/cbo-sales-person', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCbSalePersonComplete(result.data));
                }
            });
    };
}


function loadCbSalePersonComplete(data) {
    return {
        type: types.W19_W19F1030_CB_SALEPERSON_COMPLETE,
        cbSalePerson: data
    };
}

export function loadCbEvaluation(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1030/cbo-evaluation', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCbEvaluationComplete(result.data));
                }
            });
    };
}


function loadCbEvaluationComplete(data) {
    return {
        type: types.W19_W19F1030_CB_EVALUATION_COMPLETE,
        cbEvaluation: data
    };
}

export function loadCbTypeTime(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1030/cbo-type-time', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCbTypeTimeComplete(result.data));
                }
            });
    };
}


function loadCbTypeTimeComplete(data) {
    return {
        type: types.W19_W19F1030_CB_TYPETIME_COMPLETE,
        cbTypeTime: data
    };
}

export function deleteEvaluation(param, cb) {
    return (dispatch) => {
        Api.put('/W19F1030/del-evaluation', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(deleteEvaluationComplete(result.data));
                }
            });
    };
}


function deleteEvaluationComplete(data) {
    return {
        type: types.W19_W19F1030_DELETE_COMPLETE,
        deleteEvaluation: data
    };
}


