/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 13/08/2021
 * @update 13/08/2021
 */
import Api from "../../../services/api";

export function loadGridImage(param, cb) {
    return async () => {
        Api.put('/w17f2035/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}