/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/09/22 15:37
 * @update 2019/09/22 15:37
 * @file src/actions/dropdown-actions.js
 */

import Api from '../../../services/api.js';
export default async (param)=> {
    return new Promise((resolve, reject) => {
        Api.put('/w18f1051/combo-code-condition', param)
            .then(result => {
                if (result && result.code && result.message) {
                    return resolve(0);
                } else {
                    return resolve(result);
                }
            });
    });
};
