/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 15:37
 * @update 2019/03/28 15:37
 */
import Api from "../../../services/api";
import * as types from "../../types";

export function loadGrid(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1050/grid-region-list', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGridComplete(result.data));
                }
            });
    };
}

function loadGridComplete(data) {
    return {
        type: types.W18_W18F1050_DATAGRID_COMPLETE,
        loadGrid: data
    };
}

export function cbInfoTypeW18F1050(param, cb) {
    return (dispatch) => {
        Api.put('/W18f1050/combo-info-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cbInfoTypeComplete(result.data));
                }
            });
    };
}

function cbInfoTypeComplete(data) {
    return {
        type: types.W18_W18F1050_CB_INFO_COMPLETE,
        cbInfoTypeW18F1050: data
    };
}

export function cbInfoValueW18F1050(param, cb) {
    return (dispatch) => {
        Api.put('/W18f1050/combo-value', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cbInfoValueW18F1050Complete(result.data));
                }
            });
    };
}

function cbInfoValueW18F1050Complete(data) {
    return {
        type: types.W18_W18F1050_CB_INFO_VALUE_COMPLETE,
        cbInfoValueW18F1050: data
    };
}

export function cbRefInfoW18F1050(param, cb) {
    return (dispatch) => {
        Api.put('/W18f1050/combo-ref-info-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cbRefInfoW18F1050Complete(result.data));
                }
            });
    };
}

function cbRefInfoW18F1050Complete(data) {
    return {
        type: types.W18_W18F1050_CB_INFO_REF_COMPLETE,
        cbRefInfoW18F1050: data
    };
}

export function cbRefValueW18F1050(param, cb) {
    return (dispatch) => {
        Api.put('/W18f1050/combo-ref-value', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(cbRefValueW18F1050Complete(result.data));
                }
            });
    };
}

function cbRefValueW18F1050Complete(data) {
    return {
        type: types.W18_W18F1050_CB_VALUE_REF_COMPLETE,
        cbRefValueW18F1050: data
    };
}

export function deleteW18F1050(param, cb) {
    return (dispatch) => {
        Api.put('/W18f1050/del-region-id', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(deleteW18F1050Complete(result.data));
                }
            });
    };
}

function deleteW18F1050Complete(data) {
    return {
        type: types.W18_W18F1050_DELETE_COMPLETE,
        deleteW18F1050: data
    };
}

export function loadDetailW19F1051(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/grid-edit-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadMasterW19F1051Complete(result.data));
                }
            });
    };
}

function loadMasterW19F1051Complete(data) {
    return {
        type: types.W18_W18F1051_LOAD_MASTER_COMPLETE,
        loadDetailW19F1051: data
    };
}

export function loadProvinceW19F1051(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/combo-province', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadProvinceW19F1051Complete(result.data));
                }
            });
    };
}

function loadProvinceW19F1051Complete(data) {
    return {
        type: types.W18_W18F1051_LOAD_PROVINCE_COMPLETE,
        loadProvinceW19F1051: data
    };
}

export function loadTypeInformation(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/combo-type-info', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadTypeInformationComplete(result.data));
                }
            });
    };
}

function loadTypeInformationComplete(data) {
    return {
        type: types.W18_W18F1051_LOAD_TYPE_INFORMATION_COMPLETE,
        loadTypeInformation: data
    };
}export function loadTypeCondition(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/combo-type-condition', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadTypeConditionComplete(result.data));
                }
            });
    };
}

function loadTypeConditionComplete(data) {
    return {
        type: types.W18_W18F1051_LOAD_TYPE_CONDITION_COMPLETE,
        loadTypeCondition: data
    };
}

export function loadCodeInformation(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/combo-code-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCodeInformationComplete(result.data));
                }
            });
    };
}

function loadCodeInformationComplete(data) {
    return {
        type: types.W18_W18F1051_LOAD_CODE_INFORMATION_COMPLETE,
        loadCodeInformation: data
    };
}

export function loadCodeCondition(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/combo-code-condition', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCodeConditionComplete(result.data));
                }
            });
    };
}

function loadCodeConditionComplete(data) {
    return {
        type: types.W18_W18F1051_LOAD_CODE_CONDITION_COMPLETE,
        loadCodeCondition: data
    };
}

export function addW18F1051(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(addW18F1051Complete(result.data));
                }
            });
    };
}

function addW18F1051Complete(data) {
    return {
        type: types.W18_W18F1051_ADD_COMPLETE,
        addW18F1051: data
    };
}

export function editW18F1051(param, cb) {
    return (dispatch) => {
        Api.put('/w18f1051/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(editW18F1051Complete(result.data));
                }
            });
    };
}

function editW18F1051Complete(data) {
    return {
        type: types.W18_W18F1051_EDIT_COMPLETE,
        editW18F1051: data
    };
}


