/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/02/21 08:57
 * @update 2019/02/21 08:57
 */

import * as types from './types.js';
import Api from '../services/api.js';

export function loadW17F0040(param, cb) {
    return (dispatch) => {
        Api.put('/D89T0010/search', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(apiQueryComplete(result.data, 'ReportType'));
                }
            });
    };
}

export function loadW17F0040Detail(param, cb) {
    return (dispatch) => {
        Api.put('/D89T1000/search', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(apiQueryComplete(result.data, 'Report'));
                }
            });
    };
}

function apiQueryComplete(data, mode) {
    switch (mode) {
        case 'ReportType':
            return {
                type: types.W17F0040_LOAD_GRID_REPORTTYPE_COMPLETE,
                loadW17F0040: data
            };
        case 'Report':
            return {
                type: types.W17F0040_LOAD_GRID_REPORT_COMPLETE,
                loadW17F0040Detail: data
            };
        default:
            return null;

    }

}

export function addW17F0041(param, cb) {
    return (dispatch) => {
        Api.put('/D89T1000/add', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(addW17F0041Complete(result));
                }
            });
    };
}
function addW17F0041Complete(data) {
    return {
        type: types.W17_W17F0041_ADD_COMPLETE,
        addW17F0041: data
    };
}

export function editW17F0041(param, cb) {
    return (dispatch) => {
        Api.put('/D89T1000/edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(editW17F0041Complete(result));
                }
            });
    };
}
function editW17F0041Complete(data) {
    return {
        type: types.W17_W17F0041_EDIT_COMPLETE,
        editW17F0041: data
    };
}

export function delW17F0041(param, cb) {
    return (dispatch) => {
        Api.put('/D89T1000/del', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result);
                    dispatch(deleteW17F0041Complete(result));
                }
            });
    };
}
function deleteW17F0041Complete(data) {
    return {
        type: types.W17_W17F0041_DELETE_COMPLETE,
        delW17F0041: data
    };
}
