import Api from "../services/api";
import * as types from "./types";

/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @creaxte 2019/01/17 15:37
 * @update 2019/01/17 15:37
 * @file src/actions/user-actions.js
 */



export function loadGroupSalesType(param, cb) {
    return (dispatch) => {
        Api.put('/W17F1001/load-data-group-sales-type', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadGroupSalesTypeComplete(result));
                }
            });
    };
}

function loadGroupSalesTypeComplete(result) {
    return {
        type: types.W17_W17F1001_GROUPSALESTYPE_COMPLETE,
        loadGroupSalesType: result
    };
}

export function isRoot(param, cb) {
    return (dispatch) => {
        Api.put('/W17F1001/check-root-is-exist', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(isRootComplete(result));
                }
            });
    };
}

function isRootComplete(result) {
    return {
        type: types.W17_W17F1001_CHECKISROOT,
        isRoot: result
    };
}

